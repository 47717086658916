
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IVRAccount } from 'src/app/models/IVRAccount';
import { IVRConfirmation } from 'src/app/models/IVRConfirmation';
import { Order, OrderResponse } from 'src/app/models/Order';
import { AccountService } from 'src/app/services/account.service';
import { BandwidthService } from 'src/app/services/bandwidth.service';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-orderphoneconfirmation',
  templateUrl: './orderphoneconfirmation.component.html',
  styleUrls: ['./orderphoneconfirmation.component.scss']
})
export class OrderphoneconfirmationComponent implements OnInit {

  form: FormGroup;

  errorMessage: string;
  infoMessage: string;
  orderstatus: Order;
  userid : string;
  account: IVRAccount =  new IVRAccount();
  phoneorder: Order;
  constructor(private fb: FormBuilder,  private  bandwidthservice: BandwidthService, private router: Router, private state: StateService) {


   this.state.phoneorder.subscribe(result => {
                            this.phoneorder = result
                            console.log(this.phoneorder
                              )});

                              this.createForm();
  }

  private createForm() {
    this.form = this.fb.group({
      PhoneToTextInput: ['', Validators.required],
    });

    
  }

  ngOnInit(): void {
    console.log(this.phoneorder);    
  }
checkstatus()
{
 console.log('checking status of order');
  this.bandwidthservice.checkorderstatus(this.phoneorder.orderid).subscribe
  (
    {
      next: (result) => {
        this.orderstatus = result;

        console.log(this.orderstatus);
      },
      error: err => this.errorMessage = err
    }
  )
}

submit()
{
  console.log('submitting');
  console.log("form submitted");
  
  if (!this.form.controls.PhoneToTextInput)
    return ;

  console.log(this.form);

  if (this.form.valid && this.phoneorder)
  {

     this.phoneorder.phonetotext = this.form.controls.PhoneToTextInput.value;
    //this.phoneorder.phonetoorder ="8338329669";
    console.log(this.phoneorder);
  
   this.bandwidthservice.sendtext(this.phoneorder)
        .subscribe(
          {
          next: (resp: string) => this.handleSendTextResponse(resp),
          error: err => {this.errorMessage = err;console.log(err); }
        });
  }
else
{
  this.errorMessage = 'Please correct the validation errors.';

}

  

}

private handleSendTextResponse(resp: string) {
  console.log('text has been sent');
  console.log(resp);
  const obj = JSON.parse(JSON.stringify(resp))
  this.infoMessage = "Text message was sent successfully. Message ID for reference:" + obj.id;
}

goback()
{
  
  this.router.navigate(['/orderphone']);
}


}
