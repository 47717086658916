export class Order
{

    orderid : string;
    accountname : string;
    accountid  : number ;
    locationname  : string;
    phonetoorder  : string;
    applicationid  : string;
    subscriptionid  : string;
    phonetotext : string;
    responsexml : string;
    status  : string;
    UserGUID: string;
    newaccount : boolean;
}

export  class OrderResponse
{
    responsexml : string;
    orderid : string;
    errorMessage : string;
}