import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { RecordingDropDown } from '../models/Recording';
import { MyMonitoringService } from './my-monitoring-service.service';

@Injectable({
  providedIn: 'root'
})
export class CommonrecordingService {

  constructor(private http: HttpClient,
    private myMonitoringService: MyMonitoringService) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      Authorization: 'my-auth-token',
      'Apikey': environment.Apikey
    })
  };
  

  
  getCommonRecordings(): Observable<RecordingDropDown[]>
  {
    const url:string  = environment.apiUrl + "CommonRecording";
    console.log(url);
    return this.http.get<RecordingDropDown[]>(url,this.httpOptions ).pipe(
      tap(_ => console.log('fetched recordings')),
      catchError( this.handleError<RecordingDropDown[]>('recordings', []))
    );
  }

  
  createCommonRecording(id: number ) : Observable<string>{
    const url:string  = environment.apiUrl + "CommonRecording?id=" + id;
    console.log(url);
      
    return this.http.post<any>(url,{}, this.httpOptions)
      .pipe(
        tap(data => console.log('createCommonRecording: ' + JSON.stringify(data))),
        catchError(this.handleError<number[]>('createCommonRecording', []))
      );
  }

  deleteCommonRecording(id: number): Observable<string> {
    const url:string  = environment.apiUrl + "commonrecording?id=" + id ;
    console.log(url);
    
    return this.http.delete<string>(url, this.httpOptions )
      .pipe(
        tap(data => console.log('deleteCommonRecording: ' )),
        catchError(this.handleError<any>('deleteCommonRecording',[]))
      );
  }

    private handleError<T>(operation = 'operation', result?: T){
      return (error: any): Observable<T> => {
    
        this.myMonitoringService.logException(error);
       
        // TODO: send the error to remote logging infrastructure
        console.error(error); // log to console instead
    
        // TODO: better job of transforming error for user consumption
        console.log(`${operation} failed: ${error.message}`);
    
        // Let the app keep running by returning an empty result.
        return of(result as T);
      };
      }

}
