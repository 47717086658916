<div class="center" >
      <mat-card>
        <mat-card-header>
          <mat-card-title>Phone Order Confirmation</mat-card-title>
          <mat-card-subtitle>Your phone number <span class="infomessage"> <strong>{{ this.phoneorder.phonetoorder |  phoneFormat}} </strong> </span>
            been ordered and the order is in <i>queue</i>. Order number for your reference is <span class="infomessage">{{ this.phoneorder.orderid }}</span>.
           <div>  It might take few seconds before the order is complete. You can click the refresh button below to check if your order is complete</div> </mat-card-subtitle>
             <mat-card-subtitle>
          To check the status of your order, click the Refresh button. 
          <button   mat-raised-button
         
          color="primary" (click)="checkstatus();">Refresh</button>
             </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            
          <mat-list *ngIf = "this.orderstatus" >
              <mat-list-item  > 
       
              </mat-list-item>
              <mat-list-item *ngIf = "!this.orderstatus.status"> 
                  Your order is still processing.. Please click refresh again in few seconds. If it takes longer than 15 minutes, please contact IT and provide them with the orderid.
              </mat-list-item>
              <mat-list-item *ngIf = "this.orderstatus.status == 'FAILED'  "> 
                Your order has FAILED.
                <div class="warningmessage">{{ this.orderstatus.errorMessage}}</div>
            </mat-list-item>
          
            
         </mat-list>
      
            
        </mat-card-content>
      
      </mat-card>



      <mat-card *ngIf = "this.orderstatus && this.orderstatus.status == 'COMPLETE' ">
        <mat-card-header>
          <mat-card-title>Congratulations!! Your order was successfully completed. </mat-card-title>
         <mat-card-subtitle> </mat-card-subtitle>
          </mat-card-header>

        <mat-card-content >
        
          
        <form [formGroup]="form" (ngSubmit)="submit()">
          <div class="form-container">
          
            <div class="flex-item2">
              To send a sample text to validate, enter the phone number
              below you want to send text to and click Send Text. The text message will come from 
              <strong>{{ this.phoneorder.phonetoorder |  phoneFormat}} </strong>           
            </div> 
        <div class="flex-item3">

          <div class="warningmessage" *ngIf="infoMessage">{{ infoMessage }}</div>

          <mat-form-field appearance="outline">
            <input type="text"
              matInput
              formControlName="PhoneToTextInput"
              placeholder="Phone number to text to"
              required
            >
            <mat-error
              *ngIf="this.form.controls.PhoneToTextInput.hasError('required')"
            >
            PhoneToTextInput is <strong>required</strong>
            </mat-error>
          </mat-form-field>
          <button mat-stroked-button color="primary" type="submit" >Send Text</button>
      
        </div>
       
          </div>        
           
        </form>

        </mat-card-content>
      </mat-card> 
  
   
</div>
  