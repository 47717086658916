import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ChartMode, IVRAccount } from '../models/IVRAccount';
import { IVRUser } from '../models/IVRUser';
import { Order } from '../models/Order';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  private userSource = new BehaviorSubject<IVRUser>({ userid: "", username :""});
  private IVRAccountSource = new BehaviorSubject<IVRAccount>( new IVRAccount());
  private ChartModeSource  = new BehaviorSubject<ChartMode>( new ChartMode());
  private ViewedChartSource  = new BehaviorSubject<string>( "N");
  private phoneOrderSource = new BehaviorSubject<Order>( new Order());

  user = this.userSource.asObservable();
  ivraccount = this.IVRAccountSource.asObservable();
  chartmode = this.ChartModeSource.asObservable();
  viewedchart = this.ViewedChartSource.asObservable();
  phoneorder = this.phoneOrderSource.asObservable();
  
  constructor() { }

  changeUser(user: IVRUser) {
    console.log('username was updated to ->' + user.username);
    console.log('userid was updated to ->' + user.userid);
    
    this.userSource.next(user);
  }

  changeIVRAccount(ivr : IVRAccount)
  {
    
    //console.log('new account was selected  ->' + ivr.friendlyName);
     const newAccount: IVRAccount = ivr;
    this.IVRAccountSource.next(newAccount);
  }

  changeMode(mode: ChartMode)
  {
    console.log('chart mode was updated  ->' + mode.currentmode);
    const newMode: ChartMode = mode;
   this.ChartModeSource.next(newMode);

  }

  changeViewedChart(viewed: string)
  {
    console.log('chart mode was updated  ->' + viewed);
   this.ViewedChartSource.next(viewed);
 
  }

  setOrder(order: Order)
  {
    console.log('order->' + order);
    const newOrder: Order = order;
    this.phoneOrderSource.next(newOrder);
  }
}
