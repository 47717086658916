<div *ngIf="loading">
  Please wait while we load your data....
  <mat-spinner [diameter]="60"></mat-spinner>
</div>
<div *ngIf="!loading">
  <fieldset>
    <legend>{{ pageTitle }}</legend>
    <form [formGroup]="form" (ngSubmit)="submit()">
      <div class="form-container">
        <div class="flex-item">
          <mat-form-field appearance="outline">
            <mat-label
              >Select instruction type from below by clicking or type
              instruction id or text</mat-label
            >

            <input
              type="text"
              matInput
              formControlName="InstructionTypeDropDown"
              [matAutocomplete]="InstructionTypeDropDown"
            />
            <mat-autocomplete #InstructionTypeDropDown="matAutocomplete">
              <mat-option
                *ngFor="let instr of filteredinstructions | async"
                [value]="instr.display"
                (onSelectionChange)="setinstruction(instr)"
                >{{ instr.display }}
              </mat-option>
            </mat-autocomplete>

            <mat-error
              *ngIf="
                this.form.controls.InstructionTypeDropDown.hasError('required')
              "
            >
              Instruction Type is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <span class="flex-item-wide-smallfont" *ngIf="this.selectedInstruction">
          <em>You selected: </em>{{ this.selectedInstruction.display }}
        </span>

        <div class="flex-item">
          <mat-form-field appearance="outline">
            <mat-label>Description</mat-label>
            <textarea
              matInput
              formControlName="DescriptionInput"
              placeholder="Enter description for this instruction"
              required
            ></textarea>
            <mat-error
              *ngIf="this.form.controls.DescriptionInput.hasError('required')"
            >
              Description is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="flex-item"> 
        <fieldset
          *ngIf="
            this.selectedInstruction &&
            this.selectedInstruction.recordingRequired !== null
          "
        >
          <legend>Recording Configuration</legend>

          <button
            mat-raised-button
            color="primary"
            type="button"
            [title]="
              recordings.valid
                ? 'Add another Recording'
                : 'Disabled until the existing recording data is valid'
            "
            [disabled]="!recordings.valid"
            (click)="addRecordings(null)"
          >
            Add Another Recording
          </button>
          
          <form [formGroup]="recordingForm">
            <div >
                <div class="flex-item-recording"
                  formArrayName="recordings"
                  *ngFor="let item of this.getControls(); let i = index"
                >
                  <ng-container [formGroupName]="i">
                    <mat-form-field appearance="outline">
                      <mat-label attr.for="{{ 'lang' + i }}">
                        Language</mat-label
                      >
                      <mat-select
                        id="{{ 'lang' + i }}"
                        formControlName="langDropDown"
                        placeholder="Language"
                        (selectionChange)="changeLanguage($event.value)"
                      >
                        <mat-option
                          *ngFor="let rec of this.account.languages"
                          [value]="rec.language"
                          >{{ rec.displayLanguage }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                      <input
                        type="text"
                        matInput
                        formControlName="RecordingDropDown"
                        [matAutocomplete]="RecordingDropDown"
                      />
                      <mat-autocomplete
                        #RecordingDropDown="matAutocomplete"
                        [displayWith]="displayFn"
                      >
                        <mat-option
                          *ngFor="let option of filteredOptions[i] | async"
                          [value]="option"
                        >
                          {{ option.display }}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                    <button
                      mat-raised-button
                      color="primary"
                      type="button"
                      (click)="deleteRecordings(i)"
                    >
                      <mat-icon>delete</mat-icon>
                    </button>

                  </ng-container>
                </div>
            </div>
          </form>
        </fieldset>
      </div>
        <div
          class="flex-item"
          *ngIf="
            this.selectedInstruction &&
            this.selectedInstruction.gatherLengthRequired !== null
          "
        >
          <mat-form-field appearance="outline">
            <mat-label>Gather Length</mat-label>
            <input
              matInput
              formControlName="GatherLengthInput"
              placeholder="How many digits to press"
              value="{{ this.selectedInstruction.defaultGatherLength }}"
            />
          </mat-form-field>
        </div>

        <div
          class="flex-item"
          *ngIf="this.showMessageDropDown"
        >
          <mat-form-field appearance="outline">
            <mat-label
              >If this instruction captures a data element say capture
              Department/Arrival Time etc
            </mat-label>
            <mat-select
              formControlName="COPDataFieldMessageGroupDropDown"
             
            >
              <mat-option
                *ngFor="let rec of this.dataGroupsMessage"
                [value]="rec.id"
                >{{ rec.description }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>

        <div
          class="flex-item"
          *ngIf="this.showNoMessageDropDown"
        >
          <mat-form-field appearance="outline">
            <mat-label
              >If this instruction captures a data element say capture
              Department/Arrival Time etc
            </mat-label>
            <mat-select
              formControlName="COPDataFieldNoMessageGroupDropDown"
              (selectionChange)="changeFieldGroup($event.value)"
            >
              <mat-option
                *ngFor="let rec of this.dataGroupsNoMessage"
                [value]="rec.id"
                >{{ rec.description }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>


        <fieldset
          *ngIf="
            this.selectedInstruction &&
            this.selectedInstruction.promptRequired !== null
          "
        >
          <legend>Digit Configuration</legend>

          <button
            mat-raised-button
            color="primary"
            type="button"
            [title]="
              digits.valid
                ? 'Add another digit'
                : 'Disabled until the existing digit data is valid'
            "
            [disabled]="!digits.valid"
            (click)="addDigits(null)"
          >
            Add Another Digit
          </button>

          <button
          mat-raised-button
          color="primary"
          *ngIf="this.dataGroup2FieldValue"
          type="button"
          (click)="enumerateDigits(null)"
        >
          Enumerate Digits
        </button>

          <div class="form-container2">
            <div class="flex-item2" formArrayName="digits">
              <div *ngFor="let digitForm of digits.controls; let i = index">
                <div [formGroup]="digitForm">
                  <mat-form-field appearance="outline">
                    <mat-label attr.for="{{ 'digitid' + i }}">Digit</mat-label>
                    <input
                      matInput
                      id="{{ 'digitid' + i }}"
                      formControlName="digit"
                      placeholder="Ex. 1,2,*"
                      value=""
                      (focusout)="onDigitFocusOutEvent($event,i)"
                    />
                  </mat-form-field>

                  <mat-form-field appearance="outline">
                    <mat-label attr.for="{{ 'description' + i }}"
                      >Description</mat-label
                    >
                    <input
                      matInput
                      id="{{ 'description' + i }}"
                      formControlName="ddescription"
                      placeholder="description"
                      value=""
                    />
                  </mat-form-field>

                  <mat-form-field appearance="outline">
                    <mat-label attr.for="{{ 'nextroute' + i }}">
                      Next Route</mat-label
                    >
                    <mat-select
                      id="{{ 'nextroute' + i }}"
                      formControlName="dnextroute"
                      placeholder="nextroute" (selectionChange)="prompt_should_update_allroutes($event.value, i)"
                    >
                      <mat-option
                        *ngFor="let rec of this.routes"
                        [value]="rec.descriptionWithID"
                        >{{ rec.descriptionWithID }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field
                    appearance="outline"
                    *ngIf="
                      this.selectedInstruction.copRequired !== null &&
                      this.datafields.length > 0
                    "
                  >
                    <mat-label attr.for="{{ 'calloffPortalDFpk' + i }}">
                      Data Field</mat-label
                    >
                    <mat-select
                      id="{{ 'calloffPortalDFpk' + i }}"
                      formControlName="calloffPortalDFpk"
                      placeholder="calloffPortalDFpk"
                      (selectionChange)="defaultdigitdescription($event.value, i)"
                    >
                      <mat-option
                        *ngFor="let rec of this.filtereddatafields"
                        [value]="rec.id"
                        >{{ rec.description }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>

                  <button
                    mat-raised-button
                    color="primary"
                    type="button"
                    (click)="deleteDigits(i)"
                  >
                    <mat-icon>delete</mat-icon>

                    <input
                      matInput
                      id="{{ 'digitid' + i }}"
                      formControlName="pk"
                      hidden
                    />
                    <input
                      matInput
                      id="{{ 'digitid' + i }}"
                      formControlName="savedValue"
                      hidden
                    />
                    <input
                      matInput
                      id="{{ 'digitid' + i }}"
                      formControlName="setLanguage"
                      hidden
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <button
          mat-raised-button *ngIf="this.digits && this.digits.length > 0"
          color="primary"
          type="button"
          [title]="
            digits.valid
              ? 'Add another digit'
              : 'Disabled until the existing digit data is valid'
          "
          [disabled]="!digits.valid"
          (click)="addDigits(null)"
        >
          Add Another Digit
        </button>

        </fieldset>

        <fieldset
          *ngIf="this.selectedInstruction && this.showNextRoutingDropDowns"
        >
          <legend>
            Select Next Instruction Routing. If don't know yet select "-1 to be
            created"
          </legend>

          <mat-form-field
            appearance="outline"
            *ngIf="this.selectedInstruction.successPKRequired !== null"
          >
            <mat-label>Next RT on Success</mat-label>
            <input
              type="text"
              matInput
              formControlName="SuccessNextRouteDropDown"
              [matAutocomplete]="SuccessNextRouteDropDown"
            />
            <mat-autocomplete #SuccessNextRouteDropDown="matAutocomplete">
              <mat-option
                *ngFor="let rec of filteredsuccessroutes | async"
                [value]="rec.descriptionWithID"
                >{{ rec.descriptionWithID }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field
            appearance="outline"
            *ngIf="this.selectedInstruction.failedPKRequired !== null"
          >
            <mat-label>Next RT on Failure</mat-label>

            <input
              type="text"
              matInput
              formControlName="FailedNextRouteDropDown"
              [matAutocomplete]="FailedNextRouteDropDown"
            />
            <mat-autocomplete #FailedNextRouteDropDown="matAutocomplete">
              <mat-option
                *ngFor="let rec of filteredfailedroutes | async"
                [value]="rec.descriptionWithID"
                >{{ rec.descriptionWithID }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field
            appearance="outline"
            *ngIf="this.selectedInstruction.otherPKRequired !== null"
          >
            <mat-label>Next RT on Other</mat-label>
            <input
              type="text"
              matInput
              formControlName="OtherNextRouteDropDown"
              [matAutocomplete]="OtherNextRouteDropDown"
            />
            <mat-autocomplete #OtherNextRouteDropDown="matAutocomplete">
              <mat-option
                *ngFor="let rec of filteredotherroutes | async"
                [value]="rec.descriptionWithID"
                >{{ rec.descriptionWithID }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field
            appearance="outline"
            *ngIf="this.selectedInstruction.alternatePKRequired !== null"
          >
            <mat-label>Next RT on Alternate</mat-label>

            <input
              type="text"
              matInput
              formControlName="AlternateNextRouteDropDown"
              [matAutocomplete]="AlternateNextRouteDropDown"
            />
            <mat-autocomplete #AlternateNextRouteDropDown="matAutocomplete">
              <mat-option
                *ngFor="let rec of filteredalternateroutes | async"
                [value]="rec.descriptionWithID"
                >{{ rec.descriptionWithID }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </fieldset>

        <div class="flex-item3">
          <input matInput formControlName="id" hidden />

          <button mat-raised-button color="primary" (click)="goback()">
            Cancel
          </button>
          <button
            mat-raised-button
            color="primary"
            type="submit"
            [disabled]="!form.valid"
            *ngIf="!this.routeid"
          >
            Create Instruction
          </button>
          <button
            mat-raised-button
            color="primary"
            type="submit"
            [disabled]="!form.valid"
            *ngIf="this.routeid"
          >
            Update Instruction
          </button>
        </div>
      </div>
    </form>
  </fieldset>

  <div class="warningmessage" *ngIf="errorMessage">{{ errorMessage }}</div>
</div>
