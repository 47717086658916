import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { debounceTime, map, startWith, tap } from 'rxjs/operators';
import { IVRAccount } from 'src/app/models/IVRAccount';
import { Language } from 'src/app/models/Language';
import { RecordingDropDown } from 'src/app/models/Recording';
import { UploadFile } from 'src/app/models/UploadFile';
import { CommonrecordingService } from 'src/app/services/commonrecording.service';
import { DropdownService } from 'src/app/services/dropdown.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { StateService } from 'src/app/services/state.service';
@Component({
  selector: 'app-deleterecording',
  templateUrl: './deleterecording.component.html',
  styleUrls: ['./deleterecording.component.scss']
})
export class DeleterecordingComponent implements OnInit {

  errorMessage: string;
  form: FormGroup;
  languages: Language[];
  loading : boolean ;
  recordings : RecordingDropDown[];
  langrecordings : RecordingDropDown[];
  
  infoMessage: string;
  filteredrecordings : Observable<RecordingDropDown[]>;
  selectedRecording: RecordingDropDown;
  uploadfile: UploadFile;
  account: IVRAccount =  new IVRAccount();
userid: string;

  

  getErrorMessage() {
    if (this.form.controls.description.hasError('required')) {
      return 'You must enter a value';
    }

  }

  constructor(private fb: FormBuilder,  private dropdownService: DropdownService , 
    private commonrecordingService: CommonrecordingService, private state: StateService, private fileUploadService: FileUploadService, private route: ActivatedRoute,
     private router: Router) {

      this.state.ivraccount.subscribe(result => {
        this.account = result; // this set's the username to the default observable value
        console.log(this.account.accountNumber);
        console.log(this.account.friendlyName);
  
      });
  
      this.state.user.subscribe(result => {
                          this.userid = result.userid
                          console.log(this.userid
                            )});
                  
   console.log('inside create form');
   this.createForm();
                    
    
  }


  private createForm() {
    this.loading = true;
    this.form = this.fb.group({
      LanguagesDropDown: ['', Validators.required],
      RecordingDropDown: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    console.log('inside ngoninit');
      

    forkJoin( [
      this.dropdownService.getLanguages(),
      this.commonrecordingService.getCommonRecordings(),
      
  ])
  .subscribe( result => {

    this.languages = result[0];
    this.recordings= result[1];
    this.loading = false;
    this.filteredrecordings =  this.form.controls.RecordingDropDown.valueChanges
    .pipe(
      
      startWith(''),
      debounceTime(1000),

      map(value => this._filterRecording(value))
    );


   
}); 


}
  
setRecording(rec: RecordingDropDown): void
{
  console.log(`selected ${rec.pk} ${rec.description}`);
  this.selectedRecording = rec;
  
}
  


  
  private _filterRecording(value: string): RecordingDropDown[] {
    console.log('in filter recording' + value);
    let filterValue;
    if (value != null)
     filterValue = value.toLowerCase();
    
     if (this.langrecordings != null)
      return this.langrecordings.filter(option => option.display.toLowerCase().includes(filterValue)
    );

  }


OnSubmit(): void {

  console.log("form submitted");
  console.log(this.form);

  if (this.form.valid)
  {

    if (this.form.dirty) {
     console.log('changes detected in form');
    
      console.log('inserting recording');

      this.uploadfile = {} as UploadFile;
      this.uploadfile.id  = this.selectedRecording.pk;
      this.uploadfile.accountnumber = this.account.accountNumber;
      this.uploadfile.username = this.userid;
      console.log(this.uploadfile);
    
      
      this.fileUploadService.deleteExisting(this.uploadfile)
      .subscribe({
        next: () => this.onSaveComplete(),
        error: err => this.errorMessage = err
      });


  }
  else
  {
    console.log(' no changes in form');
    this.onSaveComplete();
  }


   
  }
else
{
  this.errorMessage = 'Please correct the validation errors.';

}

  
  


}


changeRecordings(value : any)
{
  this.form.controls.RecordingDropDown.reset();
  this.langrecordings = this.recordings?.filter(a=>a.language.includes(value));

}

onSaveComplete(): void {
  console.log('inside save complete');
  // Reset the form to clear the flags
  this.formReset(this.form);
    this.infoMessage = "Your recording has been successfully deleted";
}

formReset(form: FormGroup) {

  form.reset();
  this.selectedRecording = null;
  
  Object.keys(form.controls).forEach(key => {
    form.get(key).setErrors(null) ;
  });
}

goback(): void
{
  this.router.navigate(['/selectrecording']);
 
}

}
