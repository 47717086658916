
<div class="center">
<mat-card>
  <mat-card-header>
    <mat-card-title>You selected {{ this.account.accountNumber }} - {{ this.account.friendlyName }}</mat-card-title>
    <mat-card-subtitle>Click below on what you would like to do with this account.</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
      
    <mat-list>
        <mat-list-item> 
            <button mat-button color="primary" (click)="gotoChart();">View IVR Script</button>
        </mat-list-item>
        
        <mat-list-item *ngIf="IsAdminUser"> 
            <button  mat-button color="primary" (click)="gotoEditIVR();" matTooltipPosition="right"  matTooltip="Please note: Changes are made in staging area and once you are done, you (if you have permissions) or your supervisor must click 'Apply Script changes to Live Tables' to apply those changes  ">Create new script or make changes to existing IVR Script</button>
         </mat-list-item>
         
        <mat-list-item *ngIf="IsAdminUser"> 
            <button *ngIf="pendingcounts.totals > 0" matBadge="{{pendingcounts.totals}}" matBadgePosition="after" matBadgeColor="accent" mat-button color="primary" (click)="gotoViewPendingChanges();">Apply Script changes to Live Tables</button>
            <button *ngIf="pendingcounts.totals == 0" mat-button color="primary"  (click)="gotoViewPendingChanges();">Apply Script changes to Live Tables</button>

        </mat-list-item>
       
        <mat-list-item *ngIf="IsAdminUser"> 
         <button mat-button color="primary" (click)="gotoRecording();">Manage Recordings</button>
       </mat-list-item>
      
       <mat-list-item *ngIf="IsAdminUser"> 
        <button mat-button color="primary" (click)="gotoSettings();">Manage Account Settings</button>
      </mat-list-item>
      
   </mat-list>

      
  </mat-card-content>

  <mat-card-actions>
    <button mat-stroked-button color="primary" (click)="goback();">Go Back</button>
 
  </mat-card-actions>

</mat-card>
</div>

<div *ngIf="errorMessage"
     class="warningmessage">
  Error: {{ errorMessage }}
</div>