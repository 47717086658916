import { ErrorHandler, Injectable } from "@angular/core";
import { MyMonitoringService } from "./services/my-monitoring-service.service";

@Injectable()
export class ErrorHandlerService extends ErrorHandler {

    constructor(private myMonitoringService: MyMonitoringService) {
        super();
    }

    handleError(error: Error) {
        console.log('inside global handler');
        console.error(error);
        this.myMonitoringService.logException(error); // Manually log exception
    }
}