import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IVRAccount } from '../models/IVRAccount';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { StagingResult } from '../models/StagingResult';
import { Instruction } from '../models/Instruction';
import {PendingCount } from '../models/PendingCount';

import { IVRConfirmation } from '../models/IVRConfirmation';
import { MyMonitoringService } from './my-monitoring-service.service';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

   httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      Authorization: 'my-auth-token',
      'Apikey': environment.Apikey
    })
  };
  
  constructor(private http: HttpClient, private logger: NGXLogger,
    private myMonitoringService: MyMonitoringService) { }

  getaccounts(): Observable<IVRAccount[]>
  {

    const url:string  = environment.apiUrl ;
    this.logger.log(url);
    return this.http.get<IVRAccount[]>(url,this.httpOptions).pipe(
      tap(_ => this.logger.log('fetched accounts')),
      catchError( this.handleError<IVRAccount[]>('getaccounts', []))
    );
     
  }

  getaccount(accountnumber : string): Observable<IVRAccount>
  {

    const url:string  = `${environment.apiUrl}CallPathSpecificAccount?accountnumber=${accountnumber}` ;
    
    this.logger.log(url);
    return this.http.get<IVRAccount>(url, this.httpOptions).pipe(
      tap(_ => this.logger.log('fetched account')),
      catchError( this.handleError)
    );
     
  }

  updateaccount( account: IVRAccount): Observable<IVRAccount>
  {

    const url:string  = environment.apiUrl ;
    
    this.logger.log(url);
    
    return this.http.put<IVRAccount>(url, JSON.stringify(account), this.httpOptions)
    .pipe(
      tap(data => console.log('updateRoute: ' + JSON.stringify(data))),
      catchError(this.handleError)
    );


     
  }


  getInstructions(): Observable<Instruction[]>
  {
    const url:string  = environment.apiUrl + "Instruction" ;
    this.logger.log(url);
    return this.http.get<Instruction[]>(url, this.httpOptions).pipe(
      tap(_ => this.logger.log('fetched instructions')),
      catchError( this.handleError<Instruction[]>('instructions', []))
    );
    

  }

  getPendingCounts(account: string, userid: string): Observable<PendingCount>
  {
    const url:string  = `${environment.apiUrl}AccountMenu?account=${account}&username=${userid}` ;
    this.logger.log(url);
    return this.http.get<PendingCount>(url, this.httpOptions).pipe(
      tap(_ => this.logger.log('fetched pending counts')),
      catchError( this.handleError<PendingCount>('pendingcounts', null))
    );
    

  }


  createIVRAccountInStaging(accountNumber: string, userid: string): Observable<StagingResult> {
    const url:string  = environment.apiUrl + "staging/" + accountNumber + "/" + userid;
    this.logger.log(url);
      
    return this.http.put<StagingResult>(url,{}, this.httpOptions)
      .pipe(
        tap(data => this.logger.log('createaccountinStaging: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  deleteAccounInStaging(accountNumber: string, userid: string): Observable<StagingResult> {
    const url:string  = environment.apiUrl + "staging/" + accountNumber + "/" + userid;
    this.logger.log(url);
    
    return this.http.delete<StagingResult>(url ,this.httpOptions)
      .pipe(
        tap(data => this.logger.log('deleteAccounInStaging: ' )
        ),
        catchError(this.handleError)
      );
  }

  applyWizardChanges(accountNumber: string, userid: string): Observable<IVRConfirmation> {
    const url:string  = environment.apiUrl + "Confirmation/" + accountNumber + "/" + userid;
    this.logger.log(url);
      
    return this.http.put<IVRConfirmation>(url,{}, this.httpOptions)
      .pipe(
        tap(data => this.logger.log('applyWizardChanges: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

    private handleError<T>(operation = 'operation', result?: T){
      return (error: any): Observable<T> => {
    

        this.myMonitoringService.logException(error);
        // TODO: send the error to remote logging infrastructure
        this.logger.error(error); // log to console instead
    
        // TODO: better job of transforming error for user consumption
        this.logger.log(`${operation} failed: ${error.message}`);
    
        // Let the app keep running by returning an empty result.
        return of(result as T);
      };
      }
    /*( data => )
    return of( 
      [{FriendlyName: "apple", AccountNumber: "3176762884"}
      ,{FriendlyName: "microsoft", AccountNumber: "333"}
    ,{FriendlyName: "twilio", AccountNumber: "331"}
    ,{FriendlyName: "adidas", AccountNumber: "330"}
    ,{FriendlyName: "oracle", AccountNumber: "133"}
    ,{FriendlyName: "continental", AccountNumber: "233"}
    ,{FriendlyName: "fiserv", AccountNumber: "303"}
    ,{FriendlyName: "TQL", AccountNumber: "301"}
    
  ]);
  */
}


