import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { stringify } from 'querystring';

import { forkJoin, Observable, Subscription } from 'rxjs';
import { debounceTime, map, startWith, tap } from 'rxjs/operators';
import { Application } from 'src/app/models/Application';
import { IVRAccount } from 'src/app/models/IVRAccount';
import { Order, OrderResponse } from 'src/app/models/Order';
import { Phone } from 'src/app/models/Phone';
import { BandwidthService } from 'src/app/services/bandwidth.service';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-orderphone',
  templateUrl: './orderphone.component.html',
  styleUrls: ['./orderphone.component.scss']
})
export class OrderphoneComponent implements OnInit {
  pageTitle = 'Order Phone';
  errorMessage: string;
  loading: boolean;
  form: FormGroup;
  availableAccounts : IVRAccount[];
  filteredAccounts : Observable<IVRAccount[]>;
  selectedAccount: IVRAccount = new IVRAccount();
  selectedPhone : Phone;

  @ViewChild('agGrid') agGrid!: AgGridAngular;

  applications: Application[];
  selectedApplication: Application;
  
  userid : string;  
  phoneorder: Order;
  
  private gridApi;
  
  columnDefs: ColDef[] = [
    {headerName: 'Phone number',  field: 'number',  sortable: true, filter: true , checkboxSelection: true },
    {headerName: 'Rank', field: 'rank' , sortable: true, filter: true },

    
    
];

rowData : any[];
 
  constructor(private fb: FormBuilder,
     private state: StateService, private bandwidthservice: BandwidthService , private route: ActivatedRoute,
     private router: Router) {

    this.state.user.subscribe(result => {
                        this.userid = result.userid
                        console.log(this.userid
                          )});

   this.createForm();
                    
    
  }

  onSelectionChanged() {
    this.selectedPhone = null;
    const selectedNodes = this.agGrid.api.getSelectedNodes();
    console.log(selectedNodes);
        const selectedData = selectedNodes.map(node => node.data);
        if (selectedData.length > 0)
        {
          this.selectedPhone = selectedData[0];
          console.log(this.selectedPhone);
        }
        

        }


  getSelectedRows(): void {
      const selectedNodes = this.agGrid.api.getSelectedNodes();
      console.log(selectedNodes);
          const selectedData = selectedNodes.map(node => node.data);
          if (selectedData.length > 0)
          {
            this.selectedPhone = selectedData[0];
            console.log(this.selectedPhone);
          }
          else
            alert(' nothing selected');
          

       }

 showAvailableNumbersButton(): boolean
{


  if (this.selectedAccount.accountNumber == "-1" && this.form.controls.AccountNameInput.value && this.form.controls.LocationInput.value && this.selectedApplication)
    return true;

  if (this.selectedAccount.accountNumber != "-1" && this.form.controls.LocationInput.value && this.selectedApplication)
    return true;

    return false;
}

showOrderPhoneButton(): boolean
{
  if (this.showAvailableNumbersButton() && this.selectedPhone )
    return true;
  else
    return false;
}


setApplicationId(value: any)
{
  this.selectedApplication = value;
  console.log(this.selectedApplication);
}

  private createForm() {
    this.loading = true;
    this.form = this.fb.group({
      AccountTypeDropDown: ['', Validators.required],
      LocationInput: ['', Validators.required],
      ApplicationidDropDown: ['', Validators.required],
      AccountNameInput: ['', Validators.required]
    //  PhoneFilter: ['', Validators.minLength(3)]
    });

    
  }

  ngOnInit(): void {
    console.log('inside ngoninit');
      forkJoin( [
        this.bandwidthservice.getaccounts().pipe(tap((result) => this.availableAccounts= result)),
        this.bandwidthservice.getapplications().pipe(tap((result) => this.applications = result)),
    ])
    .subscribe(([ , ]) => {

      this.loading= false;
      this.filteredAccounts =  this.form.controls.AccountTypeDropDown.valueChanges
      .pipe(
        startWith(''),

        map(value => this.filterAccount(value))
      );
      
   console.log(this.applications);

   
   
     
  }); 


}
  


setaccount(acct: IVRAccount): void
{

  this.selectedAccount = acct;
  console.log(this.selectedAccount);

  if ( this.selectedAccount.accountNumber == "-1" )
    return; 
  else
    this.form.controls.AccountNameInput.setValue ( this.selectedAccount.friendlyName);


  if (!this.form.controls.LocationInput.value )
  {
    this.form.controls.LocationInput.setValue ( this.selectedAccount.friendlyName);
  }
}

showavailableNumbers()
{
  console.log("calling searchphones");
 /*  let filter: string;
  if (this.form.controls.PhoneFilter.value)
    filter = this.form.controls.PhoneFilter.value;
  else
    filter = ""; 

  console.log(filter); */
  
  this.bandwidthservice.showavailableNumbers().subscribe
    (
      {
        next: (result) => {
          this.rowData = result;

          console.log(this.rowData);
        },
        error: err => this.errorMessage = err
      }
    )
    
}
private filterAccount(name: string): IVRAccount[] {
 
  let filterValue = name.toLowerCase();
  return this.availableAccounts.filter(account => 
      account.friendlyName.toLowerCase().indexOf(filterValue) != -1
      || account.accountNumber.toLowerCase().indexOf(filterValue) != -1
      
    );
}

getSelectedRowData() {
  let selectedNodes = this.gridApi.getSelectedNodes();
  let selectedData = selectedNodes.map(node => node.data);
  alert(`Selected Nodes:\n${JSON.stringify(selectedData)}`);
  return selectedData;
}


submit() {

  
  console.log("form submitted");
  
  if (!this.selectedPhone)
    return ;

  console.log(this.form);

  if (this.form.valid && this.selectedPhone)
  {

     this.phoneorder  = {} as Order;
     if (this.selectedAccount.accountNumber == "-1")
     {
  
      this.phoneorder.accountid =   +this.selectedPhone.number;
      this.phoneorder.accountname =  this.form.controls.AccountNameInput.value;
      this.phoneorder.newaccount = true;
    
     }
     else
     {
       this.phoneorder.accountid =  +this.selectedAccount.accountNumber;
      this.phoneorder.accountname =  this.selectedAccount.friendlyName.toString();
      this.phoneorder.newaccount = false;
     }
    this.phoneorder.locationname= this.form.controls.LocationInput.value;
    this.phoneorder.applicationid = this.form.controls.ApplicationidDropDown.value;
    this.phoneorder.phonetoorder = this.selectedPhone.number;
    this.phoneorder.UserGUID = this.userid;
    //this.phoneorder.phonetoorder ="8338329669";
    console.log(this.phoneorder);
  
   this.bandwidthservice.orderphone(this.phoneorder)
        .subscribe(
          {
          next: (resp: OrderResponse) => this.handleOrderResponse(resp),
          error: err => {this.errorMessage = err;console.log(err); }
        });
  }
else
{
  this.errorMessage = 'Please correct the validation errors.';

}

  

}


private handleOrderResponse(resp: OrderResponse) {
  console.log('Order Phone Confirmation');
  console.log(resp);
  
    if (resp.errorMessage)
    {
      console.error(resp.errorMessage);
      this.errorMessage = resp.errorMessage;
      return;
    }
   
    this.phoneorder.orderid = resp.orderid;
    this.phoneorder.responsexml = resp.responsexml;
    console.log(this.phoneorder);
    this.form.reset();
    this.state.setOrder(this.phoneorder);
    this.router.navigate(['/orderphoneconfirmation']);

}



}