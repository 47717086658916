import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-selectrecording',
  templateUrl: './selectrecording.component.html',
  styleUrls: ['./selectrecording.component.scss']
})
export class SelectrecordingComponent implements OnInit {

  
  errorMessage: string;
  
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  uploadNewRecording()
  {
    
    this.router.navigate(['/uploadrecording']);
  }

  updateNewRecording()
  {
    
    this.router.navigate(['/updaterecording']);
  }
  deleteRecording()
  {
    
    this.router.navigate(['/deleterecording']);
  }
  
  markcommonRecording()
  {

    this.router.navigate(['/addcommonrecording']);
  }
  goback()
  {
    this.router.navigate(['/accountselectmenu']);

  }
}
