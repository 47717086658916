import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UploadFile } from '../models/UploadFile';
import { MyMonitoringService } from './my-monitoring-service.service';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      Authorization: 'my-auth-token',
      'Apikey': environment.Apikey
    }),
    reportProgress: true,
    observe: 'events'


  };
  


  constructor(private http: HttpClient,
    private myMonitoringService: MyMonitoringService) { }

   // Returns an observable
   upload(uploadfile : UploadFile):Observable<any> {
  
    const url:string  = environment.apiUrl + "UploadFile" ;
    console.log(uploadfile);
   
    // Create form data
    const formData = new FormData(); 
      
    // Store form name as "file" with file data
    formData.append("file", uploadfile.file, uploadfile.file.name );
    formData.append("language", uploadfile.language );
    formData.append("description", uploadfile.description );
    formData.append("transcription", uploadfile.transcription );
    formData.append("common", uploadfile.commonrecording );
    formData.append("accountnumber", uploadfile.accountnumber);
    formData.append("userid", uploadfile.username);
      
    // Make http post request over api
    // with formData as req
    return this.http.post(url, formData , { headers: {
      Authorization: 'my-auth-token',
      'Apikey': environment.Apikey
    }, reportProgress: true, observe: 'events'  });
}


// Returns an observable
uploadexisting(uploadfile : UploadFile):Observable<any> {
  
  const url:string  = environment.apiUrl + "UploadFile" ;
  console.log(uploadfile);
 
  // Create form data
  const formData = new FormData(); 
    
  // Store form name as "file" with file data
  formData.append("id", uploadfile.id );
  formData.append("file", uploadfile.file, uploadfile.file.name );
  formData.append("language", uploadfile.language );
  formData.append("description", uploadfile.description );
  formData.append("transcription", uploadfile.transcription );
  formData.append("accountnumber", uploadfile.accountnumber);
  formData.append("userid", uploadfile.username);
  
    
  // Make http post request over api
  // with formData as req
  return this.http.put(url, formData,  { headers: {
    Authorization: 'my-auth-token',
    'Apikey': environment.Apikey
  }, reportProgress: true, observe: 'events'  });
}

deleteExisting(uploadfile :UploadFile): Observable<{}> {
  //const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  const url:string  = `${environment.apiUrl}UploadFile?id=${uploadfile.id}&username=${uploadfile.username}&accountnumber=${uploadfile.accountnumber}`;
 
  return this.http.delete<UploadFile>(url,{ headers: {
    'Content-Type': 'application/json',
    Authorization: 'my-auth-token',
    'Apikey': environment.Apikey
  }})
    .pipe(
      tap(data => console.log('deleteExisting : ' + data)),
      catchError(this.handleError)
    );
}

private handleError(err: HttpErrorResponse): Observable<never> {
  // in a real world app, we may send the server to some remote logging infrastructure
  // instead of just logging it to the console
  let errorMessage = '';
  if (err.error instanceof ErrorEvent) {
    // A client-side or network error occurred. Handle it accordingly.
    errorMessage = `An error occurred: ${err.error.message}`;
  } else {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong,
    errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
  }
  this.myMonitoringService.logException(err);
       
  console.error(errorMessage);
  return throwError(errorMessage);
}


}
