

<div class="center">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Select the template for which you want to create multiple instructions?

        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
      
        
        <form  [formGroup]="myform" >

            <div class="form-container">
              
                     <div class="flex-item">
          
                    <mat-form-field appearance="outline">
                  
                        <mat-select   (selectionChange)="changetemplate($event.value)" formControlName ="TemplateTypeDropDown">
                        <mat-option     *ngFor="let instr of templates "
                          [value]="instr"  >{{instr}}
                        </mat-option>
                        </mat-select>          
                  
                    </mat-form-field>
                    
                    </div>

            </div>          
        </form>

      </mat-card-content>
    
      <mat-card-actions>
        
      &nbsp;
        <button mat-stroked-button color="primary" (click)="onCloseCancel()">CANCEL</button>
        <button mat-stroked-button color="primary" (click)="onCloseConfirm()">CONFIRM</button>
      </mat-card-actions>
    
    </mat-card>
    <p></p>
    <div class="flex-item" *ngIf='this.selectedTemplate'>
      <div class="warningmessage"> If you choose this template, it will create the following instructions below, the id numbers will change accordingly. Click confirm to create these instructions.</div>
      <img class="imgsize" src='../../assets/images/{{this.selectedTemplate}}.PNG'>
    </div>

    </div>
    
    