<div class="center">
<mat-card  class="course-card mat-elevation-z10">
  <mat-card-header>
    <mat-card-title>Account Selection</mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <div class="form-container">
      <mat-form-field >
        <mat-label>Select Account</mat-label>
        <input type="text"
               placeholder="Pick account"
               aria-label="Select Account"
               matInput
               [formControl]="myControl"
               [matAutocomplete]="auto">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
          <mat-option *ngFor="let account of filteredAccounts | async" [value]="account.accountNumber" (onSelectionChange)="getAccount(account)">
            {{account.accountNumber}} - {{account.friendlyName}}      </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

  </mat-card-content>
</mat-card>
</div>