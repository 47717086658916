import { HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IVRAccount } from 'src/app/models/IVRAccount';
import { Language } from 'src/app/models/Language';
import { RecordingDropDown } from 'src/app/models/Recording';
import { UploadFile } from 'src/app/models/UploadFile';
import { DropdownService } from 'src/app/services/dropdown.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-uploadrecording',
  templateUrl: './uploadrecording.component.html',
  styleUrls: ['./uploadrecording.component.scss']
})
export class UploadrecordingComponent implements OnInit {

  loading: boolean = false; // Flag variable
  file: File = null; // Variable to store file
  form: FormGroup;
  languages: Language[];
  errorMessage: string;
  filename: string ;
  infoMessage: string;
    uploadfile: UploadFile;
    account: IVRAccount =  new IVRAccount();
  userid: string;

  columnDefs: ColDef[] = [
    {headerName: 'ID',  field: 'pk',  sortable: true, filter: true },
    {headerName: 'Transcription', field: 'transcribe' , sortable: true, filter: true },
    
    { headerName: 'Uploaded filename', field: 'downloadname' , sortable: true, filter: true},
    { headerName: 'Timestamp when uploaded', field: 'stamp' , sortable: true},
    { field: 'language',  sortable: true, filter: true},
    { headerName: 'CommonRecording', field: 'sharedrecording' , sortable: true}
    
    
    
];

rowData : any[];
   
  constructor( private fb: FormBuilder, private fileUploadService: FileUploadService, private state: StateService,
    private dropdownService: DropdownService , private router: Router) {

      this.state.user.subscribe(result => {
        this.userid = result.userid
        console.log(this.userid
          )});
          
          this.state.ivraccount.subscribe(result => {
            this.account = result; // this set's the username to the default observable value
            console.log(this.account.accountNumber);
            console.log(this.account.friendlyName);
      
          });
   
          
     }

  ngOnInit(): void {

    
    this.getrecordings();


    this.dropdownService.getLanguages()
      .subscribe({
        next: (result) => this.languages = result,
        error: err => this.errorMessage = err
      });
    this.filename = null;
    this.createForm();
  }

  // On file Select
  onChange(event) {
    this.file = event.target.files[0];
    this.filename = this.file.name;
}

private getrecordings()
{

  this.dropdownService.getRecordings(this.account.accountNumber).subscribe
    (
      {
        next: (result) => {
          this.rowData = result;

          console.log(this.rowData);
        },
        error: err => this.errorMessage = err
      }
    )
    

}

private createForm() {
    this.loading = true;
    this.form = this.fb.group({
        LanguagesDropDown: ['', Validators.required],
        CommonRecordingInput : [false, Validators.required],
        TranscriptionInput: ['', Validators.required],
        DescriptionInput: ['', Validators.required]
    });
  }


// OnClick of button Upload
submit() 
{
    console.log("form submitted");
    console.log(this.form);
    
    if (this.form.valid)
    {
    
      if (this.form.dirty) 
      {
       
        console.log('changes detected in form');
        console.log(this.file);

        this.uploadfile = {} as UploadFile;
        this.uploadfile.file = this.file;
        this.uploadfile.description = this.form.controls.DescriptionInput.value;
        this.uploadfile.transcription = this.form.controls.TranscriptionInput.value;
        this.uploadfile.filename = this.filename;
        this.uploadfile.language = this.form.controls.LanguagesDropDown.value;
        this.uploadfile.commonrecording = this.form.controls.CommonRecordingInput.value;
        this.uploadfile.accountnumber = this.account.accountNumber;
        this.uploadfile.username = this.userid;

        console.log(this.uploadfile);
        
        this.fileUploadService.upload(this.uploadfile)
        .subscribe(
                (event: any) => {
                                      if (event.type == HttpEventType.Response) {
                                        this.onSaveComplete();
                                    }
                                }
                    );
        }
      else
      {
      

        console.log(' no changes in form');
        //this.onSaveComplete("");
      }
    }
    else
    {
        this.errorMessage = 'Please correct the validation errors.';
    
    }
    
    
    
    
    
}


onSaveComplete(): void {
    console.log('inside save complete');
    // Reset the form to clear the flags
    this.formReset(this.form);
  
    this.infoMessage = "Your recording has been successfully added";

    
    this.getrecordings();


  }
  
  formReset(form: FormGroup) {
  
    form.reset();
    this.filename = null;
    Object.keys(form.controls).forEach(key => {
      form.get(key).setErrors(null) ;
    });
  }
  
  

goback(): void
{
  this.router.navigate(['/selectrecording']);
 
}


}
