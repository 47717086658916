<div *ngIf="loading">
    Please wait while we load your data....
    <mat-spinner [diameter]="60"></mat-spinner>
  </div>
  <div *ngIf="!loading">




    <div class="center" *ngIf="confirmationnumber">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Confirmation</mat-card-title>
          <mat-card-subtitle>Your changes have been successfully applied</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            
          <mat-list>
              <mat-list-item> 
                Your confirmation number is :<span class="infomessage"> {{confirmationnumber}} </span>. 
   
              </mat-list-item>
              <mat-list-item> 
                You can call in : <span class="infomessage"> {{phone}} </span> to validate your changes.
         
               </mat-list-item>
               
         </mat-list>
      
            
        </mat-card-content>
      
        <mat-card-actions>
          <button mat-stroked-button color="primary" (click)="goback();">Back to menu</button>
       
        </mat-card-actions>
      
      </mat-card>
      </div>
      <div class="center" *ngIf="errorMessage">
        <mat-card>
          <mat-card-header>
            <mat-card-title>Error</mat-card-title>
            <mat-card-subtitle>An error occured while migrating the data </mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
              
        
              
          </mat-card-content>
        
          <mat-card-actions>
            <button mat-stroked-button color="primary" (click)="goback();">Back to menu</button>
         
          </mat-card-actions>
        
        </mat-card>
        </div>
      
  
