
<div  *ngIf="loading">
    Please wait while we load your data....
    <mat-spinner [diameter]="60"></mat-spinner>
  </div>
  
  <div *ngIf="!loading"><fieldset>
      <legend>  Pick the recording that you would like to update</legend>
  
  
      <div class="message">{{infoMessage}}</div> 
      <form  [formGroup]="form" (ngSubmit)="OnSubmit($event.submitter.id)">
    
      <div class="form-container">
        
        <div class="flex-item" >
    
          <mat-form-field appearance="outline">
              <mat-label>Pick the language </mat-label>
              <mat-select formControlName ="LanguagesDropDown" (selectionChange)="changeRecordings($event.value)">
                  <mat-option *ngFor="let rec of this.languages"
                  [value]="rec.language">{{rec.displayLanguage}}</mat-option>
              </mat-select>
            </mat-form-field>
  
        </div>
        <div class="flex-item-wide" >
    
                <mat-form-field appearance="outline" >
                  <mat-label>Type recording transcription text or recording id and then click down arrow to see all matching results</mat-label>
                  <input type="text" matInput formControlName ="RecordingDropDown" [matAutocomplete]="RecordingDropDown">
                  <mat-autocomplete autoActiveFirstOption #RecordingDropDown="matAutocomplete" >
      
                      <mat-option (onSelectionChange)="setRecording(rec)"  *ngFor="let rec of this.filteredrecordings | async"
                      [value]="rec.display">{{rec.display}}</mat-option>
                  </mat-autocomplete>
                </mat-form-field>
    
        </div>
    
        <span class="flex-item-wide-smallfont" *ngIf="this.selectedRecording">
                <em>You selected: </em>{{ this.selectedRecording.display }}
        </span>
              
    
        <div class="flex-item">
  
		
            <input   type="file" #file placeholder="Choose file" (change)="onChange($event)" style="display:none;">
            <button mat-button color="primary" type="button" class="btn btn-success" (click)="file.click()">

              <mat-icon>attach_file</mat-icon>
              Upload file

            </button>

            <mat-label>{{filename || "No file uploaded yet."}}
          </mat-label>

          
          </div>
   
          <div class="flex-item">
  

            <mat-form-field  appearance="outline">

              <mat-label>Description</mat-label>
              <input matInput formControlName="DescriptionInput" placeholder="Enter description for this instruction" required>
              <mat-error *ngIf="this.form.controls.DescriptionInput.hasError('required')">
                Description is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>

          
          <div class="flex-item">


              <mat-form-field  appearance="outline">

                <mat-label>Transcription</mat-label>
                <textarea matInput formControlName="TranscriptionInput" placeholder="Enter description for this instruction" required></textarea>
                <mat-error *ngIf="this.form.controls.TranscriptionInput.hasError('required')">
                  Transcription is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>

        <div class="flex-item3">
        
              <button mat-raised-button color="primary" (click)="goback()" >Cancel</button>
  
            <button mat-raised-button color="primary" type="submit"  id="update">Update Recording</button>
          </div>
  
      </div>
        </form>
    </fieldset>
    
    <div class="warningmessage"
    *ngIf="errorMessage">{{errorMessage}}
    </div>
           
            </div>
  
    
      
          
           
           