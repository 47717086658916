import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CallpathrouteDetailComponent } from './callpathroute-detail.component';
import { CallPathRouteListComponent } from './callpathroute-list.component';

 
 
const routes: Routes = [
    {   path: 'callpathroutes',   component: CallPathRouteListComponent    },
    {   path: 'callpathroutesdetail/:id',   component: CallpathrouteDetailComponent    }
    

  ]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CallPathRoutingModule { }