<div *ngIf="loading">
  Please wait while we load your data....
  <mat-spinner [diameter]="60"></mat-spinner>
</div>


<div class="warningmessage" *ngIf="errorMessage">{{ errorMessage }}</div>

<div>
  <button mat-stroked-button color="primary" (click)="goback();">Go Back</button>
 
<button    mat-button color="primary" (click)="gotoChart()">
  <mat-icon class="mat-18">note_add</mat-icon>
  Flowchart mode
</button>


<div fxLayout fxLayoutAlign="center center">
  <mat-form-field fxFlex="40%">
    <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Filter">
  </mat-form-field>
       <button  mat-button color="primary" (click)="addnew()">
      <mat-icon class="mat-18">note_add</mat-icon>
      Add new Instruction
  </button>
  <button  mat-button color="primary" (click)="addnewtemplate()">
    <mat-icon class="mat-18">note_add</mat-icon>
    Add Multiple Instructions from Template
</button>


</div>

  <table mat-table [dataSource]="dataSource" matSort>

     <!-- Routes Linked Column -->
     <ng-container matColumnDef="routesLinked">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Routes Linked </th>
      <td mat-cell *matCellDef="let row" > 
       
        
        <mat-icon class="error" matTooltip="{{row.errorMessage}}"
         *ngIf="row.errorMessage">error</mat-icon>
        <mat-icon class="success" *ngIf="!row.errorMessage">check_circle</mat-icon>

      </td>
    </ng-container>

    <!-- ID Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
      <td mat-cell *matCellDef="let row" > {{row.id}} </td>
    </ng-container>


      <!-- Has Digits Column -->
      <ng-container matColumnDef="hasDigits">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Instruction Type </th>
        <td mat-cell *matCellDef="let row" matTooltip="{{row.instructiondescription}}" matTooltipPosition="right"
        > {{row.instructiontext}}        
          
<!--           <mat-icon *ngIf='row.digitPress == "Y"' class="mat-18">done</mat-icon>
          
          <mat-icon *ngIf='row.digitPress == "N"' class="mat-18">clear</mat-icon>
 -->        </td>
      </ng-container>
  
      <!-- Description Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
        <td mat-cell *matCellDef="let row" > {{row.description}} </td>
      </ng-container>
  
     
      <!-- Next Route Description-->
      <ng-container matColumnDef="nextRouteDescription">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Next Route Description </th>
        <td mat-cell *matCellDef="let row"  >
          
          <select   *ngIf="row.nextroutes.length > 1">
            <option   *ngFor="let rec of row.nextroutes">{{rec}}</option>
          </select>
        <mat-label *ngIf="row.nextroutes.length < 2">{{row.nextroutes[0]}}</mat-label>

           </td>
      </ng-container>
  
        <!-- Is Play-->
        <ng-container matColumnDef="isPlay">
          <th mat-header-cell *matHeaderCellDef >  </th>
          <td mat-cell *matCellDef="let row" > <mat-icon *ngIf='row.recordingID'  class="mat-18" (click)="openAudioDialog(row)">play_circle_filled</mat-icon> </td>
        </ng-container>

        <ng-container matColumnDef="update">
          <th mat-header-cell *matHeaderCellDef> Update </th>
          <td mat-cell *matCellDef="let row">
            <button mat-icon-button  (click)="redirectToUpdate(row.id)">
                <mat-icon class="mat-18">edit</mat-icon>

          
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef> Delete </th>
            <td mat-cell *matCellDef="let row">
              <button mat-icon-button color="purple" (click)="openDeleteConfirmDialog(row)">
                  <mat-icon class="mat-18">delete</mat-icon>
              </button>
            </td>
          </ng-container>
     
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[ 100, 200]"></mat-paginator>

    <button mat-stroked-button color="primary" (click)="goback();">Go Back</button>
 
  </div>