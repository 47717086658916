<div *ngIf="!loginDisplay">
    <p class="welcome">Welcome to the IVR Flowchart Wizard</p>
</div>

<div *ngIf="loginDisplay">
    <p>Login successful!</p>

    <h3>Welcome <strong> {{profile?.givenName}} {{profile?.surname}}</strong></h3>
    <p>Click on <a routerLink='accountselection'>Account</a> to view and work with your specific IVR account.</p>
    
</div>
