
<div class="center">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Select the action<p></p>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
          <p>
        <mat-list>
            <mat-list-item> 
                <button mat-button color="primary" (click)="uploadNewRecording();">Upload new Recording </button>
                 </mat-list-item>
                 <mat-list-item> 
                  <button mat-button color="primary" (click)="updateNewRecording();">Update existing Recording</button>
                   </mat-list-item>
                   <mat-list-item> 
                    <button mat-button color="primary" (click)="deleteRecording();">Delete existing Recording</button>
                     </mat-list-item>
              
              
                 <mat-list-item> 
                <button mat-button color="primary" (click)="markcommonRecording();">Setup existing recording as a common/shared recording</button>
                 </mat-list-item>

       </mat-list>

           
        </p>
        
      </mat-card-content>
    
      <mat-card-actions>
        <button mat-stroked-button color="primary" (click)="goback();">Go Back</button>
     
      </mat-card-actions>
    
    </mat-card>
    </div>
    
    <div *ngIf="errorMessage"
         class="alert alert-danger">
      Error: {{ errorMessage }}
    </div>