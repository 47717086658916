import { NgModule } from '@angular/core';
import { CallPathRouteListComponent } from './callpathroute-list.component';
import { ConvertToSpacesPipe } from '../shared/convert-to-spaces.pipe';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CallPathRoutingModule } from './callpathroute-routing.module';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from '../modules/app-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CallpathrouteDetailComponent } from './callpathroute-detail.component';
import { MatInputModule } from '@angular/material/input';


@NgModule({
  declarations: [
    CallPathRouteListComponent,
    CallpathrouteDetailComponent,
    ConvertToSpacesPipe
  ],
  imports: [
    CommonModule,
    CallPathRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    AppMaterialModule,
    FlexLayoutModule  ,
    MatInputModule
  ]
})
export class CallPathRouteModule { }
