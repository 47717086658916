import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CalloffportalDropDown } from '../models/CalloffportalDropdown';
import { DataField } from '../models/DataField';
import { DataGroup } from '../models/DataGroup';
import { Instruction } from '../models/Instruction';
import { IVRNextRoute } from '../models/IVRNextRoute';
import { Language } from '../models/Language';
import { RecordingDropDown } from '../models/Recording';
import { Timezone } from '../models/Timezone';
import { MyMonitoringService } from './my-monitoring-service.service';

@Injectable({
  providedIn: 'root'
})
export class DropdownService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      Authorization: 'my-auth-token',
      'Apikey': environment.Apikey
    })
  };
  
  
  constructor(private http: HttpClient,
    private myMonitoringService: MyMonitoringService) { }

  getInstructions(): Observable<Instruction[]>
  {
    const url:string  = environment.apiUrl + "Instructions" ;
    console.log(url);
    return this.http.get<Instruction[]>(url, this.httpOptions).pipe(
      tap(_ => console.log('fetched instructions')),
      catchError( this.handleError<Instruction[]>('instructions', []))
    );
  }

  getRecordings(accountnumber: string): Observable<RecordingDropDown[]>
  {
    const url:string  = `${environment.apiUrl}AccountRecordings?account=${accountnumber}`;
    

    console.log(url);
    return this.http.get<RecordingDropDown[]>(url, this.httpOptions).pipe(
      tap(_ => console.log('fetched recordings')),
      catchError( this.handleError<RecordingDropDown[]>('recordings', []))
    );
  }


  
  getLanguages(): Observable<Language[]>
  {
    const url:string  = environment.apiUrl + "Language" ;
    console.log(url);
    return this.http.get<Language[]>(url, this.httpOptions).pipe(
      tap(_ => console.log('fetched languages')),
      catchError( this.handleError<Language[]>('languages', []))
    );
  }

  getCalloffPortalValues(): Observable<CalloffportalDropDown[]>
  {
    const url:string  = environment.apiUrl + "CalloffPortalValue" ;
    console.log(url);
    return this.http.get<CalloffportalDropDown[]>(url, this.httpOptions).pipe(
      tap(_ => console.log('fetched calloffportaldropdown')),
      catchError( this.handleError<CalloffportalDropDown[]>('calloffportaldropdown', []))
    );
  }


  getTimeZones(): Observable<Timezone[]>
  {
    const url:string  = environment.apiUrl + "Timezone" ;
    console.log(url);
    return this.http.get<Timezone[]>(url, this.httpOptions).pipe(
      tap(_ => console.log('fetched timezones')),
      catchError( this.handleError<Timezone[]>('timezones', []))
    );
  }

  getNextRoutesDropDown( accountnumber: string, username: string): Observable<IVRNextRoute[]>
  {
    const url:string  = `${environment.apiUrl}CallPathNextRoute?AccountNumber=${accountnumber}&username=${username}`;
    

    console.log(url);
    return this.http.get<IVRNextRoute[]>(url, this.httpOptions).pipe(
      tap(_ => console.log('fetched routes dropdowns')),
      catchError( this.handleError<IVRNextRoute[]>('nextroutedropdown', []))
    );
  }

  getDataFields( accountnumber: string): Observable<DataField[]>
  {
    const url:string  = `${environment.apiUrl}DataFields?AccountNumber=${accountnumber}`;
    

    console.log(url);
    return this.http.get<DataField[]>(url, this.httpOptions).pipe(
      tap(_ => console.log('fetched data fields dropdowns')),
      catchError( this.handleError<DataField[]>('getDataFields', []))
    );
  }


  getDataGroups( accountnumber: string): Observable<DataGroup[]>
  {
    const url:string  = `${environment.apiUrl}DataGroup?AccountNumber=${accountnumber}`;
    

    console.log(url);
    return this.http.get<DataGroup[]>(url, this.httpOptions).pipe(
      tap(_ => console.log('fetched data groups dropdowns')),
      catchError( this.handleError<DataGroup[]>('getDataGroups', []))
    );
  }

  getMessageDataGroups( accountnumber: string): Observable<DataGroup[]>
  {
    const url:string  = `${environment.apiUrl}MessageDataGroup?AccountNumber=${accountnumber}`;
    

    console.log(url);
    return this.http.get<DataGroup[]>(url, this.httpOptions).pipe(
      tap(_ => console.log('fetched message data groups dropdowns')),
      catchError( this.handleError<DataGroup[]>('getMessageDataGroups', []))
    );
  }
  
  private handleError<T>(operation = 'operation', result?: T){
    return (error: any): Observable<T> => {
  
      this.myMonitoringService.logException(error);
       
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
  
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
    }
}
