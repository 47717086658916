
<div class="center">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Our records indicate that you already have pending changes for this account {{ this.account.accountNumber }} - {{ this.account.friendlyName }}. 
            <p></p>
            Do you want 
            to continue with those changes or discard previous changes and start new?
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>
          <mat-list>
            <mat-list-item> 
                New instructions added: {{ pendingcounts.inserts }}
                 </mat-list-item>
            <mat-list-item> 
                Existing Instructions updated: {{ pendingcounts.updates }}    
            </mat-list-item>
            <mat-list-item> 
              Existing Instructions Deleted: {{ pendingcounts.deletes }}    
            </mat-list-item>

        </mat-list>
        </p><p>
          <mat-list>
            <mat-list-item> 
              <button mat-button color="primary" (click)="continue();">Continue</button>
              </mat-list-item>
            <mat-list-item> 
              <button mat-button color="primary" (click)="confirmCancelDialog(false);">Discard previous changes and start with fresh copy</button>
            </mat-list-item>
            <mat-list-item> 
                  <button mat-button color="primary" (click)="confirmCancelDialog(true);">Discard previous changes , I am no longer working on this (Release it for other user)</button>
            </mat-list-item>
        </mat-list>
         
  
             
          </p>
        
      </mat-card-content>
    
      <mat-card-actions>
        <button mat-stroked-button color="primary" (click)="goback();">Go Back</button>
     
      </mat-card-actions>
    
    </mat-card>
    </div>
    
    <div *ngIf="errorMessage"
         class="alert alert-danger">
      Error: {{ errorMessage }}
    </div>