<h1></h1>

<!-- <div class="left">
  Palette
  <gojs-palette #myPalette [initPalette]='initPalette' [divClassName]='paletteDivClassName' [nodeDataArray]='paletteNodeData'
    [linkDataArray]='paletteLinkData' [modelData]='paletteModelData' (modelChange)='paletteModelChange($event)' [skipsPaletteUpdate]='skipsPaletteUpdate'></gojs-palette>
</div> -->

<div class="center">

  <div class="example-button-row">

  <button mat-stroked-button color="primary" (click)="doDownloadImage();" title="Click to download an image of the diagram above to your device as type 'png'."> Download Image</button>

  <input type="range" id="scaleSlider" class="slider" min="0.25" max="5" step="0.25" value="1" #scaleSlider
         title="Drag the slider button to the right or to the left to increase or decrease the scale."
         (input)="scaleSliderChange(scaleSlider.value);"


         />

  
 <input type="number" id="scaleField" name="scale" min="0.25" max="5" step="0.25" value="1" #scaleField
 (keypress)="handleScaleKeyPress($event)"
 title="Click the up or down arrowKey to increase or decrease the scale, or just enter the value directly into this field."
 (click)="doScale();" />


  <button mat-stroked-button color="primary" (click)="doAutoFit();"
   title="Click to auto fit the full contents of the diagram into the view port."> Auto Center</button>


</div>




<div id="gojs" class="left">
  <gojs-diagram #myDiagram [initDiagram]='initDiagram' [nodeDataArray]='diagramNodeData' [linkDataArray]='diagramLinkData'
    [divClassName]='diagramDivClassName' [modelData]='diagramModelData' [skipsDiagramUpdate]='skipsDiagramUpdate'
    (modelChange)='diagramModelChange($event)' >
  </gojs-diagram>

  <button mat-stroked-button color="primary" (click)="goback();">Go Back</button>


</div>

</div>

<div class="left" >
  Overview
  <gojs-overview #myOverview [initOverview]='initOverview' [divClassName]='oDivClassName' [observedDiagram]='observedDiagram'></gojs-overview>


  <app-inspector [mode]='mode' [selectedNode]='selectedNode' (onFormChange)='handleInspectorChange($event)'></app-inspector>
 
</div>





<!-- 
<div class="clear left box">
  <h3>Links in app data</h3>
  <ul>
    <li *ngFor="let ld of diagramLinkData">
      Link Key: {{ld.key}}, To: {{ld.to}}, From: {{ ld.from }}
    </li>
  </ul>
</div>

<div class="left box">
  <h3>Nodes in app data</h3>
  <ul>
    <li *ngFor="let nd of diagramNodeData">
      Node Key: {{nd.key}}, Text: {{nd.text}}, Color: {{nd.color}} 
    </li>
  </ul>
</div> -->
