import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as go from 'gojs';
import { ChartMode, ChartModeEnum } from 'src/app/models/IVRAccount';
import { StateService } from 'src/app/services/state.service';
import { AudioComponent } from '../audio/audio.component';

@Component({
  selector: 'app-inspector',
  templateUrl: './inspector.component.html',
  styleUrls: ['./inspector.component.css']
})
export class InspectorComponent {


  public _selectedNode: go.Node;
  public data = {
    key: null,
    question: null,
    transcription: null,
    recordingkey: null,
    say: null,
    successCPpk: null,
    failureCPpk: null,
    otherCPpk: null,
    alternateCPpk: null,
    instructionkey: null,
    gatherlength: null,
    dpk: null,
    cPpk: null,
    callofportaldpk: null

  };

  @Input()
  public model: go.Model;

  @Input()
  public mode: ChartMode;

  @Output()
  public onFormChange: EventEmitter<any> = new EventEmitter<any>();
  viewedchart: string;

  @Input()
  get selectedNode() { return this._selectedNode; }
  set selectedNode(node: go.Node) {
    if (node) {
      this._selectedNode = node;
      this.data.key = this._selectedNode.data.key;
      this.data.question = this._selectedNode.data.question;
      this.data.transcription = this._selectedNode.data.transcription;
      this.data.recordingkey= this._selectedNode.data.recordingkey;
      this.data.say  = this._selectedNode.data.say;
      this.data.successCPpk= this._selectedNode.data.successCPpk;
      this.data.failureCPpk = this._selectedNode.data.failureCPpk;
      this.data.otherCPpk= this._selectedNode.data.otherCPpk;
      this.data.alternateCPpk= this._selectedNode.data.alternateCPpk;
      this.data.instructionkey = this._selectedNode.data.instructionkey;
      this.data.gatherlength = this._selectedNode.data.gatherlength;
      this.data.dpk = this._selectedNode.data.dpk;
      this.data.cPpk = this._selectedNode.data.cPpk;
      this.data.callofportaldpk = this._selectedNode.data.callofportaldpk;
  


    } else {
      this._selectedNode = null;
      this.data.key = null;
      this.data.question = null;
      this.data.transcription = null;
      this.data.recordingkey= null;
      this.data.say  = null;
      this.data.successCPpk = null;
      this.data.failureCPpk =  null;
      this.data.otherCPpk= null;
      this.data.alternateCPpk= null;
      this.data.instructionkey = null;
      this.data.gatherlength = null;
      this.data.dpk = null;
      this.data.cPpk = null;
      this.data.callofportaldpk = null;
  
    }
  }

  

  openDialog() {


    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
        title: this.data.transcription,
        id: this.data.recordingkey
    };


    const dialogRef = this.dialog.open(AudioComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  ngOnInit(): void {
   
   this.state.viewedchart.subscribe(result => {
    this.viewedchart = result
    console.log(this.viewedchart          )});


  }
  
  
  gotoEditInstruction()
  {
    const id: string = this.data.key.toString();
    
    this.router.navigate([`callpathroutesdetail/${id}`]);
  
  }

  gotoViewPendingChanges(): void
  {
    this.state.changeViewedChart("Y");


    this.router.navigate(['/viewpendingchanges']);
   
  }


  constructor(public dialog: MatDialog, private router: Router,  private state: StateService) { }

  public onCommitForm() {
    this.onFormChange.emit(this.data);
  }

}
