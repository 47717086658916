import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { RowColumnDefinition } from 'gojs';
import { Subscription } from 'rxjs';
import { AudioComponent } from '../components/audio/audio.component';
import { IVRAccount } from '../models/IVRAccount';
import { StateService } from '../services/state.service';
import { ICallPathRoute } from './callpathroute';
import { CallpathrouteDeleteDialogComponent } from './callpathroute-delete-dialog.component';
import { CallpathrouteTemplateDialogComponent } from './callpathroute-template-dialog.component';
import { CallPathRouteService } from './callpathroute.service';

@Component({
  templateUrl: './callpathroute-list.component.html',
  styleUrls: ['./callpathroute-list.component.scss']
})
export class CallPathRouteListComponent implements OnInit, OnDestroy {
  pageTitle = 'Routes';
  errorMessage = '';
  sub!: Subscription;
  account: IVRAccount =  new IVRAccount();
  loading: boolean;
  showFlowchartMode: boolean;
  displayedColumns = ['routesLinked', 'id', 'hasDigits', 'description',  'nextRouteDescription', 'isPlay', 'update', 'delete'];
  
  dataSource: MatTableDataSource<ICallPathRoute>;
/*
  private _listFilter = '';
  get listFilter(): string {
    return this._listFilter;
  }
  set listFilter(value: string) {
    this._listFilter = value;
    this.filteredcallpathroutes = this.performFilter(value);
  }
  */

  filteredcallpathroutes: ICallPathRoute[];
  callpathroutes: ICallPathRoute[] ;
  userid: string;

  constructor(public dialog: MatDialog, private callPathRouteService: CallPathRouteService, 
      private route: ActivatedRoute, private state: StateService, private router: Router, private ref: ChangeDetectorRef) {

    //this.account.accountNumber = this.route.snapshot.paramMap.get('accountnumber');

    //this.account.friendlyName = this.route.snapshot.paramMap.get('friendlyname');
    console.log('constructor  of callpathroutelist  called');

    this.state.ivraccount.subscribe(result => {
      this.account = result; // this set's the username to the default observable value
      console.log(this.account.accountNumber);
      console.log(this.account.friendlyName);

    });

    this.state.user.subscribe(result => {
      this.userid = result.userid
      console.log(this.userid
        )});

        //this.account.accountNumber = "8885929322";
        //this.userid="06176E58-A369-45A5-82D7-07DA379F3FE8";

    this.dataSource = new MatTableDataSource();
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }
/* 
  performFilter(filterBy: string): ICallPathRoute[] {
    filterBy = filterBy.toLocaleLowerCase();
    return this.callpathroutes.filter((callpathroute: ICallPathRoute) =>
    callpathroute.description.toLocaleLowerCase().includes(filterBy));
  }
   */
  
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
/* 
   applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
  */

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.loading = true;
    console.log('ngoninit of callpathroutelist called');
    this.sub = this.callPathRouteService.getRoutes(this.account.accountNumber, this.userid).subscribe({
      next: routes => {
        console.log(routes);
        this.callpathroutes = routes;
        this.filteredcallpathroutes = this.callpathroutes;
        this.dataSource.data =this.filteredcallpathroutes ;
        this.loading = false;
        console.log(this.callpathroutes);
        var  routeerrors = this.callpathroutes.find(a=>a.errorCode != null && a.errorCode.includes("100"));
        if (routeerrors != null)
          this.showFlowchartMode= false;
        else
          this.showFlowchartMode = true;

      },
      error: err => this.errorMessage = err
    });
  }

  ngOnDestroy(): void {
    console.log('destroy on callpathlist was called');
    this.sub.unsubscribe();
  }


  onRowClicked(row) {
    console.log('Row clicked: ', row);
}


gotoChart(): void
{
  console.log('navigating to ' + this.account.accountNumber);
  if (this.showFlowchartMode)
    this.router.navigate(['/viewchart',this.account.accountNumber]);
  else
    this.errorMessage = "You must assign all routes and clear unassigned routes related errors before you can view in flowchart mode";
}


public redirectToUpdate = (id: string) => {
  console.log('update clicked: ', id);
  this.router.navigate([`callpathroutesdetail/${id}`]);

}
public redirectToDelete = (id: string) => {
  console.log('delete clicked: ', id); 
}

goback()
{
  this.router.navigate(['accountselectmenu']);

}
addnew()
{
  console.log('add new  clicked:');
  
  this.router.navigate(['callpathroutesdetail/0']);

}

openAudioDialog(row: ICallPathRoute) {


  const dialogConfig = new MatDialogConfig();

  dialogConfig.disableClose = true;
  dialogConfig.autoFocus = true;

  dialogConfig.data = {
    //  title: row.,
      id: row.recordingID
  };

  console.log(row.recordingID);
  const dialogRef = this.dialog.open(AudioComponent, dialogConfig);

  dialogRef.afterClosed().subscribe(result => {
    console.log(`Dialog result: ${result}`);
  });
}


openDeleteConfirmDialog(row: ICallPathRoute) {


  const dialogConfig = new MatDialogConfig();

  dialogConfig.disableClose = true;
  dialogConfig.autoFocus = true;

  dialogConfig.data = {
      route: row

  };


  const dialogRef = this.dialog.open(CallpathrouteDeleteDialogComponent, dialogConfig);

  dialogRef.afterClosed().subscribe(result => {
    console.log(`Dialog result: ${result}`);
   // window.location.reload();
   console.log(this.router.url);
   this.ngOnInit();
  });
}



addnewtemplate()
{
  console.log('add new template  clicked:');
  
  const dialogConfig = new MatDialogConfig();

  dialogConfig.disableClose = true;
  dialogConfig.autoFocus = true;


  const dialogRef = this.dialog.open(CallpathrouteTemplateDialogComponent, dialogConfig);

  dialogRef.afterClosed().subscribe(result => {
    console.log(`Dialog result: ${result}`);
   console.log(this.router.url);
   this.ngOnInit();
  });
}



}


