import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Model } from 'gojs';
import { NGXLogger } from 'ngx-logger';

import {  ChartMode, ChartModeEnum, IVRAccount } from 'src/app/models/IVRAccount';
import { PendingCount } from 'src/app/models/PendingCount';
import { StagingResult } from 'src/app/models/StagingResult';
import { AccountService } from 'src/app/services/account.service';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-accountselectmenu',
  templateUrl: './accountselectmenu.component.html',
  styleUrls: ['./accountselectmenu.component.scss']
})
export class AccountselectmenuComponent implements OnInit {

   userid : string;  
   account: IVRAccount = new IVRAccount();
   pendingcounts: PendingCount = new PendingCount();
   IsAdminUser : boolean;
  errorMessage: string;
  
  constructor( private  accountservice: AccountService, private route: ActivatedRoute, private router: Router, 
    private state: StateService,  private logger: NGXLogger) { 
    //this.account.accountNumber = this.route.snapshot.paramMap.get('accountnumber');

    //this.account.friendlyName = this.route.snapshot.paramMap.get('friendlyname');
    console.log('constructor of account select menu');
    this.state.ivraccount.subscribe(result => {
      this.account = result; // this set's the username to the default observable value
      console.log('fetching from state service');
      console.log(this.account);
      console.log(this.account.accountNumber);
      console.log(this.account.friendlyName);


    });

    this.state.user.subscribe(result => {
                        this.userid = result.userid
                        console.log(this.userid
                          )});


  }

  checkIfAdmin()
  {
     //akash
     if (this.userid == "698c1975-dc3f-44d8-95a8-6e3f6202ee9c"  
     ||
     // akash 2 for test
     this.userid == "06176e58-a369-45a5-82d7-07da379f3fe8"
     // michael
     || this.userid == "ad41872e-4e6f-46ba-a7e0-027cdcab692d" 
     //brandon
     || this.userid == "557f6079-1539-4848-9f64-e447c2411de1" 
     // nick
     || this.userid == "293577a8-b17d-4afe-b34a-1ce761a2fcf9" )
       this.IsAdminUser = true;
     else
     this.IsAdminUser = false;

  }
  ngOnInit(): void {

   this.checkIfAdmin();
   
    console.log('ngoninit of accountselectmenu called');
     this.accountservice.getPendingCounts(this.account.accountNumber, this.userid).subscribe({
      next: counts => {
        console.log(counts);
        this.pendingcounts = counts;
      },
      error: err => this.errorMessage = err
    });
  }


  gotoChart(): void
  {
    console.log('navigating to ' + this.account.accountNumber);
    let mode: ChartMode = new ChartMode();
    mode.currentmode = ChartModeEnum.View;
    this.state.changeMode(mode);

    this.router.navigate(['/viewchart',this.account.accountNumber]);
   
  }

  gotoViewPendingChanges(): void
  {

    this.state.changeViewedChart("N");
    this.router.navigate(['/viewpendingchanges']);
   
  }
  
  gotoEditIVR(): void
  {
    let mode: ChartMode = new ChartMode();
    mode.currentmode = ChartModeEnum.Edit;
    this.state.changeMode(mode);


    console.log(`navigating to editivr using ${this.account.accountNumber} and ${this.userid}`);
    this.accountservice.createIVRAccountInStaging( this.account.accountNumber,this.userid)
    .subscribe(
    (resp: StagingResult) => this.handleStagingResponse(resp),
    err => console.log(err)
  );
    
    
  }


  private handleStagingResponse(resp: StagingResult) {
    console.log('handle create account staging');
   console.log(resp);
    if (resp.errorMessage.length > 0)
      {
        console.error(resp.errorMessage);
        this.errorMessage = resp.errorMessage;
        return;
      }

    if (resp.recordFound == 0)
    {
      this.router.navigate(['/callpathroutes']);
   
    }
    
    if (resp.recordFound == 1)
    {

      if (this.pendingcounts.totals == 0)
      {
        this.router.navigate(['/callpathroutes']);
      }
      else
      {
        this.router.navigate(['/accountpendingchanges']);
      }
    }
    
    if (resp.recordFound == 2)
    {
      this.errorMessage = "Some other user is editing this IVR";   
    }
    

  }






  goback(): void{
    this.router.navigate(['/accountselection']);
  
  }

  gotoRecording(): void
  {
    this.router.navigate(['/selectrecording']);
  
  }
  gotoSettings(): void
  {
    this.loadAccount();
    
  }

  private loadAccount() {
    this.accountservice.getaccount(this.account.accountNumber)
     
      .subscribe(
      (account: IVRAccount) => {
        this.account = account
        this.state.changeIVRAccount(account);

        this.router.navigate(['/accountsettings']);
   
      },
      err => this.logger.error(err)
    );
  }


}
