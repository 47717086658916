import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faGrinTongueSquint } from '@fortawesome/free-solid-svg-icons';

import { forkJoin, Observable, Subscription } from 'rxjs';
import { debounceTime, map, startWith, tap } from 'rxjs/operators';
import { DataField } from '../models/DataField';
import { DataGroup } from '../models/DataGroup';
import { Instruction } from '../models/Instruction';
import { IVRAccount } from '../models/IVRAccount';
import { IVRNextRoute } from '../models/IVRNextRoute';
import { RecordingDropDown } from '../models/Recording';
import { DialogService } from '../services/dialog.service';
import { DropdownService } from '../services/dropdown.service';
import { StateService } from '../services/state.service';
import { ICallPathRoute, IDigit, ILangRecording } from './callpathroute';
import { CallPathRouteService } from './callpathroute.service';
@Component({
  selector: 'app-callpathroute-detail',
  templateUrl: './callpathroute-detail.component.html',
  styleUrls: ['./callpathroute-detail.component.scss']
})
export class CallpathrouteDetailComponent implements OnInit {

  pageTitle = 'CallPathRoute Instruction Edit';
  errorMessage: string;

  
  private sub: Subscription;
  loading: boolean;

  form: FormGroup;
  instructions : Instruction[];
  filteredinstructions : Observable<Instruction[]>;
  
  acctrecordings : RecordingDropDown[];
  //filteredacctrecordings : Observable<RecordingDropDown[]>;

  filteredrecordings : Observable<RecordingDropDown[]>;

  datafields : DataField[];
  filtereddatafields : DataField[];
  dataGroupsMessage: DataGroup[];
  dataGroupsNoMessage: DataGroup[];
  dataGroup2FieldValue: number;
  routes  : IVRNextRoute[];
  filteredsuccessroutes: Observable<IVRNextRoute[]>;
  filteredfailedroutes: Observable<IVRNextRoute[]>;
  filteredotherroutes: Observable<IVRNextRoute[]>;
  filteredalternateroutes: Observable<IVRNextRoute[]>;
  filtereddigitroutes: Observable<IVRNextRoute[]>[];
  callpathroute : ICallPathRoute;
  userid : string;  
  selectedInstruction?: Instruction;
  //selectedRecording: RecordingDropDown;
  account: IVRAccount = new IVRAccount();
  showNextRoutingDropDowns: boolean;
  routeid : number = 0;
  langoptions: RecordingDropDown[];
  filteredOptions: Observable<RecordingDropDown[]>[] = [];
  recordingForm: FormGroup;
  showNoMessageDropDown: boolean;
  showMessageDropDown: boolean;
  get digits(): FormArray {
    return this.form.get('digits') as FormArray;
  }




  getErrorMessage() {
    if (this.form.controls.description.hasError('required')) {
      return 'You must enter a value';
    }

  }

  changeFieldGroup(value : any)
  {
      this.filtereddatafields = this.datafields.filter(a=>a.groupID.includes(value));
      this.dataGroup2FieldValue = value;
      console.log('selected ->' + this.dataGroup2FieldValue);

      console.log(this.filtereddatafields);
    
  }

  
  defaultdigitdescription(value : any, i: number)
  {

    const description = this.filtereddatafields.filter(a=>a.id.includes(value))[0].description;
    const digitcontrols = this.digits.controls[i] as FormArray;
    const ddescription = digitcontrols.controls['ddescription'] as FormControl;
   // if (!ddescription.value )
     ddescription.setValue(description);
    
  }
  onDigitFocusOutEvent(event : any, i:number)
  {

    console.log(event.target.value);
   const currentdigitcontrol = this.digits.controls[i] as FormArray;
    const currentcalloffportal  = currentdigitcontrol.controls['calloffPortalDFpk'] as FormControl;
    console.log(currentcalloffportal.value);
    if (event.target.value == "*")
      currentcalloffportal.setValue("");
 
  }
  prompt_should_update_allroutes(value : any,i: number)
  {

    const currentdigitcontrol = this.digits.controls[i] as FormArray;
    const currentdigit  = currentdigitcontrol.controls['digit'] as FormControl;
  
    if (currentdigit.value == "*")
     return;

    // are there more than 1 unassigned routes to prompt user, if no other unassigned routes dont prompt
    let unassignedroutes=0;
    for ( let j=0; j< this.digits.controls.length;j ++)
    {
      if (j==i)
        continue;

      const digitcontrols = this.digits.controls[j] as FormArray;
      const dnextroute = digitcontrols.controls['dnextroute'] as FormControl;
      if (!dnextroute.value || dnextroute.value.toString().startsWith('-1'))
        unassignedroutes = unassignedroutes + 1;
    }
    console.log('unassigned routes->' + unassignedroutes);
    // dont prompt if everything else is already assigned
    if (unassignedroutes < 1)
      return ;


    this.dialog
    .confirmDialog({
      title: 'Confirm Action',
      message: 'Do you want to update all digits which are not assigned routes with this route value ?',
      confirmCaption: 'Yes',
      cancelCaption: 'No, just this one',
    })
    .subscribe((confirmed) => {
      if (confirmed)
      {
        console.log('The user confirmed the action');
        for ( let j=0; j< this.digits.controls.length;j ++)
        {
          if (j==i)
            continue;
  
          const digitcontrols = this.digits.controls[j] as FormArray;
          const dnextroute = digitcontrols.controls['dnextroute'] as FormControl;
          console.log(dnextroute.value);
          if (!dnextroute.value || dnextroute.value.toString().startsWith('-1'))
            dnextroute.setValue(value);
        }
  
      } 
          });



  

    
  }
  changeLanguage(value: any)
  {
    this.langoptions = this.acctrecordings.filter(a=>a.language.includes(value));
    console.log(this.langoptions);
    
    for (let i = 0; i < this.langoptions.length - 1 ; i++) 
    {
      this.ManageNameControl(i);
  
      }

  }
  constructor(private fb: FormBuilder, private dropdownService: DropdownService,
     private state: StateService, private callpathrouteservice: CallPathRouteService , private route: ActivatedRoute,
     private router: Router, private dialog: DialogService) {

                
    this.state.ivraccount.subscribe(result => {
      this.account = result; // this set's the username to the default observable value
      console.log(this.account.accountNumber);
      console.log(this.account.friendlyName);

    });

    this.state.user.subscribe(result => {
                        this.userid = result.userid
                        console.log(this.userid
                          )});

   console.log('inside create form');
   console.log(this.account.languages);
    
   this.createForm();
                    
    
  }


  private createForm() {
    this.loading = true;
    this.form = this.fb.group({
      id: [''],
      InstructionTypeDropDown: ['', Validators.required],
      DescriptionInput: ['', Validators.required],
      GatherLengthInput: [],
      COPDataFieldMessageGroupDropDown: [],
      COPDataFieldNoMessageGroupDropDown: [],
      SuccessNextRouteDropDown: [''],
      FailedNextRouteDropDown: [''],
      OtherNextRouteDropDown: [''],
      AlternateNextRouteDropDown: [''],

      recordingForm : this.recordingForm,
      digits: this.fb.array([])
    });

    this.recordingForm = this.fb.group({
      recordings: this.initItems()
    });

    
    console.log('form created',this.recordingForm);
  }

  initItems() {
    console.log('inside init');
    var formArray = this.fb.array([]);
    
    for (let i = 0; i < 1; i++) {
      formArray.push(this.fb.group({
        langDropDown: ['', [Validators.required]],
        RecordingDropDown: ['', [Validators.required]],
      }));
    }
    return formArray;
  }
  ManageNameControl(index: number) {
    var arrayControl = this.recordingForm.get('recordings') as FormArray;
    console.log('inside managename control');
    
    console.log(index); 
    this.filteredOptions[index] = arrayControl.at(index).get('RecordingDropDown').valueChanges
      .pipe(
      startWith<string | RecordingDropDown>(''),
      map(value => typeof value === 'string' ? value : value.display),
      map(name => name ? this._filter(name) : this.langoptions.slice())
      );
      
  }

  getControls() {
    const controls = <FormArray>this.recordingForm.controls['recordings'];
    return controls.controls;
  }

  ngOnInit(): void {
    console.log('inside ngoninit');
      forkJoin( [
        this.dropdownService.getInstructions().pipe(tap((result) => this.instructions = result)),
        this.dropdownService.getRecordings(this.account.accountNumber).pipe(tap((result) => this.acctrecordings = result)),
          this.dropdownService.getNextRoutesDropDown(this.account.accountNumber,this.userid).pipe(tap((result) => this.routes = result)),
          this.dropdownService.getDataFields(this.account.accountNumber).pipe(tap((result) => this.datafields = result)),
          this.dropdownService.getDataGroups(this.account.accountNumber).pipe(tap((result) => this.dataGroupsNoMessage = result)),
          this.dropdownService.getMessageDataGroups(this.account.accountNumber).pipe(tap((result) => this.dataGroupsMessage= result)),
          
        
    ])
    .subscribe(([ , , , ,]) => {

/*
      this.user = this.userService.loadUser().pipe(
         tap(user => this.form.patchValue(user))
         );

        */
       console.log('routes');
        console.log(this.routes);
      this.loading= false;
      this.filteredinstructions =  this.form.controls.InstructionTypeDropDown.valueChanges
      .pipe(
        startWith(''),

        map(value => this._filterInstruction(value))
      );
      
     /*  this.filteredacctrecordings =  this.form.controls.RecordingDropDown.valueChanges
      .pipe(
        
        startWith(''),
        debounceTime(1000),

        map(value => this._filterRecording(value))
      );
      
 */
      this.filteredsuccessroutes =  this.form.controls.SuccessNextRouteDropDown.valueChanges
      .pipe(
        
        startWith(''),

        map(value => this._filterRoute(value))
      );
      
      this.filteredfailedroutes =  this.form.controls.FailedNextRouteDropDown.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterRoute(value))
      );

      this.filteredotherroutes =  this.form.controls.OtherNextRouteDropDown.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterRoute(value))
      );

      this.filteredalternateroutes =  this.form.controls.AlternateNextRouteDropDown.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterRoute(value))
      );
      
     /*  this.filtereddatafields = this.form.controls.COPDataFieldGroupDropDown.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterDataField(value))
      ); */

      this.langoptions = this.acctrecordings;
    console.log('datafields');
      console.log(this.datafields);
   console.log(' message datagroups 1');
    
   console.log(this.dataGroupsMessage);
   console.log('  message datagroups 2');
    
   console.log(this.dataGroupsNoMessage);
      
    this.sub = this.route.paramMap.subscribe(
      params => {
         this.routeid = +params.get('id');
        
        if (this.routeid > 0)
        {
          console.log(`fetching route for id ${this.routeid}`);
          this.getRoute(this.routeid.toString());
        }
        else
        {
          console.log('new form');
          this.displayRoute(null);
        }
      }

     
    );

     
  }); 


}
   

  

  

  

  getRoute(id: string): void {
    //this.account.accountNumber= "8885929322";
    //this.userid="06176E58-A369-45A5-82D7-07DA379F3FE8";
    this.callpathrouteservice.getRoute(this.account.accountNumber, this.userid, id)
      .subscribe({
        next: (route: ICallPathRoute) => this.displayRoute(route[0]),
        error: err => {console.error(err); this.errorMessage = err}
      });
  }

  displayRoute(r: ICallPathRoute): void {
    console.log('inside displayroute');
    

    if (this.form) {
      console.log('resetting form');
      this.form.reset();
      this.digits.clear();
      this.recordings.clear();
      this.selectedInstruction = null;
     // this.selectedRecording = null;
    }
    
    if (r === null) {
      this.pageTitle = 'Create new Instruction/Call Path Route';
      console.log(this.datafields);
    } 
    else
     {
      
      this.pageTitle = `Edit Instruction: ${r?.id} `;

      console.log('updating form data now');
      console.log(r.instructionID);
     // const instructiondesc  = this.lookupInstructionByDesc(r.instructionID)?.display;
      //console.log(instructiondesc);
     // const recordingdesc =  this.lookupRecordingbyID(r.recordingID)?.display;
      console.log('looking up instruction');
     //const instr = this.lookupInstructionByDesc(instructiondesc);
     //console.log(instr);

    const instr = this.lookupInstructionByID(+r.instructionID);
    const instructiondesc = instr?.display;
     this.setinstruction(instr);
   
      

    //  COPDataFieldMessageGroupDropDown: [],
     // COPDataFieldNoMessageGroupDropDown: [],
      let COPDataFieldNoMessageGroupid;
      if (r.digits?.length > 0 )
      {
        console.log(r.digits);

         const calloffportalpk= this.getcalloffportalpkfromdigit(r.digits);
         console.log('calloffportal to be used is->' + calloffportalpk);

          let datafield =  this.datafields.filter(a=>a.id ==  calloffportalpk);
          if (datafield?.length > 0)
          {
            COPDataFieldNoMessageGroupid = datafield[0].groupID;
            this.changeFieldGroup(COPDataFieldNoMessageGroupid);
      
          }
      }
       // Update the data on the form
      this.form.patchValue({
        id: r.id,
        InstructionTypeDropDown : instructiondesc,
        DescriptionInput: r.description,
        GatherLengthInput: r.gatherLength,
        COPDataFieldMessageGroupDropDown :  r.calloffportalpk,
        COPDataFieldNoMessageGroupDropDown :  COPDataFieldNoMessageGroupid,
        
        SuccessNextRouteDropDown: this.lookupRoutebyID(r.nextRouteSuccessID)?.descriptionWithID,
        FailedNextRouteDropDown: this.lookupRoutebyID(r.nextRouteFailureID)?.descriptionWithID,
        OtherNextRouteDropDown: this.lookupRoutebyID(r.nextRouteOtherID)?.descriptionWithID,
        AlternateNextRouteDropDown: this.lookupRoutebyID(r.nextRouteAlternateID)?.descriptionWithID,
        digits: r.digits,
        recordings: r.recordings
      });

      r.digits?.forEach(d=>this.addDigits(d));
      r.recordings?.forEach(d=>this.addRecordings(d));
    }


    }

    getcalloffportalpkfromdigit(digits: IDigit[]): string
    {
      for(var d of digits)
      {
        console.log(d.calloffPortalDFpk);
          if (d.calloffPortalDFpk)
            return d.calloffPortalDFpk;
      }
      return null;
    }
   
    setinstruction(instr: Instruction): void
    {
      console.log(`selected ${instr.id} ${instr.description}`);
  
      this.selectedInstruction = instr;
      this.setvalidations();
  
    if (this.selectedInstruction.successPKRequired !== null || this.selectedInstruction.failedPKRequired !== null
      ||this.selectedInstruction.otherPKRequired !== null || this.selectedInstruction.alternatePKRequired !== null )
      this.showNextRoutingDropDowns = true;
      else
      this.showNextRoutingDropDowns = false;

      this.showMessageDropDown = this.selectedInstruction &&
      this.selectedInstruction.copRequired !== null &&
      this.dataGroupsMessage.length > 0;
      console.log('show drop down 1');
      console.log(this.showMessageDropDown);
 


     this.showNoMessageDropDown =  this.selectedInstruction && 
     this.selectedInstruction.name == 'Gather' &&
     this.selectedInstruction.copRequired !== null &&
     this.dataGroupsNoMessage.length > 0;
      console.log('show drop down 2');
     console.log(this.showNoMessageDropDown);
    }
    /*
    lookupInstructionByDesc(instructiondesc: string): Instruction
    {
      console.log('looking up');
      console.log(instructiondesc);
      console.log(this.instructions);
      const r = this.instructions.filter(a=>a.display.includes(instructiondesc))[0];
      console.log(r);
      return r;
  
    }
  */
    lookupInstructionByID(id: number): Instruction
    {
  
      const r = this.instructions.filter(a=>a.id === id)[0];
      return r;
  
    }
    
    lookupRoutebyDesc(routedes: string): IVRNextRoute
    {
      const r = this.routes.filter(a=>a.descriptionWithID === routedes)[0];
      return r;
  
    }
    
  
    lookupRoutebyID(id: string): IVRNextRoute
    {
      
      const r = this.routes.filter(a=>a.id === +id)[0];
      return r;
  
    }

/*     lookupdataGroupsMessagebyID(id: string): DataGroup
    {
      
      const r = this.dataGroupsMessage.filter(a=>a.id === id)[0];
      return r;
  
    }
    
    
    lookupdataGroupsNoMessagebyID(id: string): DataGroup
    {
      
      const r = this.dataGroupsNoMessage.filter(a=>a.id === id)[0];
      return r;
  
    }
    
    
    lookupdatafieldbyID(id: string): DataField
    {
      
      const r = this.datafields.filter(a=>a.id === id)[0];
      return r;
  
    }
 */    
    
    lookupRecordingbyID(id: string): RecordingDropDown
    {
  

      const r = this.acctrecordings.filter(a=>a.pk === id)[0];
      return r;
  
    }
  
  /* 
    setRecording(rec: RecordingDropDown): void
    {
      console.log(`selected ${rec.pk} ${rec.description}`);
  
      this.selectedRecording = rec;
  
    }
   */

ngOnDestroy(): void {
  this.sub.unsubscribe();
}

  private _filterRoute(value: string): IVRNextRoute[] {
    console.log('in filter routes' + value);
    let filterValue;
    if (value != null)
     filterValue = value.toLowerCase();
    
    return this.routes.filter(option => option.descriptionWithID.toLowerCase().includes(filterValue)
    );

  }


  private _filterInstruction(value: string): Instruction[] {
    console.log('in filter instruction' + value);
    let filterValue;
    if (value != null)
     filterValue = value.toLowerCase();
    
    return this.instructions.filter(option => option.display.toLowerCase().includes(filterValue)
    );

  }

  
  private _filterRecording(value: string): RecordingDropDown[] {
    console.log('in filter recording' + value);
    let filterValue;
    if (value != null)
     filterValue = value.toLowerCase();
    
    return this.acctrecordings.filter(option => option.display.toLowerCase().includes(filterValue)
    );

  }

  addDigits(d: IDigit): void {
   
   
    this.form.markAsDirty();
    const itemToAdd = this.fb.group({
      digit: [d?.digit, Validators.required],
      ddescription: [d?.ddescription, Validators.required],
      dnextroute: [this.lookupRoutebyID(d?.dnextrouteid)?.descriptionWithID, Validators.required],
      pk: [d?.pk],
      savedValue: [d?.savedValue],
      setLanguage: [d?.setLanguage],
      calloffPortalDFpk: [d?.calloffPortalDFpk]

    });
    this.digits.push(itemToAdd);
  }

  enumerateDigits(d: IDigit): void {

    console.log('enumerating...');
    this.form.markAsDirty();

    //const ddescriptionarray = this.digits.controls['ddescription'];
    //console.log(ddescriptionarray);
    for(var f of this.filtereddatafields)
    {

      
      let itemToAdd = this.fb.group({
        digit: [d?.digit, Validators.required],
        ddescription: [f.description, Validators.required],
        dnextroute: [this.lookupRoutebyID(d?.dnextrouteid)?.descriptionWithID, Validators.required],
        pk: [d?.pk],
        savedValue: [d?.savedValue],
        setLanguage: [d?.setLanguage],
       
        calloffPortalDFpk: [f.id]
  
      });
      let found: boolean = false;
      for ( let j=0; j< this.digits.controls.length;j ++)
      {
       
        const digitcontrols = this.digits.controls[j] as FormArray;
        const dcalloffPortalDFpk = digitcontrols.controls['calloffPortalDFpk'] as FormControl;
        if (dcalloffPortalDFpk.value )
        {
          if (dcalloffPortalDFpk.value ==  f.id)
            {
              found = true;
            }
        }
          
      }
      
      if (!found)
        this.digits.push(itemToAdd);

    }
   

  }

  addRecordings(d: ILangRecording): void {
   console.log('inside add');
   console.log(d?.recordingID);
   
    this.form.markAsDirty();

    const itemToAdd = this.fb.group({
      langDropDown: [d?.lang, Validators.required],
      RecordingDropDown: [this.lookupRecordingbyID(d?.recordingID), Validators.required],
      
    });
    this.recordings.push(itemToAdd);
  }

  deleteDigits(i :number): void {
    this.form.markAsDirty();
    this.digits.removeAt(i);
    
  }

  
  deleteRecordings(i :number): void {
    this.form.markAsDirty();
    this.recordings.removeAt(i);

    this.filteredOptions.splice(i, 1);

    
  }

  get recordings(): FormArray {
    return this.recordingForm.get('recordings') as FormArray;
  }


  displayFn(recording?: RecordingDropDown): string | undefined {
    return recording ? recording.display : undefined;
  }

  private _filter(name: string): RecordingDropDown[] {
    const filterValue = name.toLowerCase();
    console.log('inside filter' + this.langoptions);

    return this.langoptions.filter(option => option.display.toLowerCase().includes(filterValue));

   }
  
  setvalidations(): void
  {
    console.log(this.selectedInstruction);
    console.log(`activating dynamic validations for ${this.selectedInstruction.id}`);
    if (!this.selectedInstruction)
      return;
      
    
    if (this.selectedInstruction.gatherLengthRequired )
    {
      console.log('gather length is now required');
      this.form.controls.GatherLengthInput.setValidators(Validators.required);
      
    }
    else
    {
      this.form.controls.GatherLengthInput?.clearValidators();

    }
  /*   if (this.selectedInstruction.recordingRequired )
    {
      console.log('recording is now required');
      this.form.controls.RecordingDropDown.setValidators(Validators.required);
      
    }
    else
    {
      this.form.controls.RecordingDropDown?.clearValidators();

    } */

    if (this.selectedInstruction.copRequired )
    {
      console.log('COP is now required');
      this.form.controls.COPDataFieldMessageGroupDropDown.setValidators(Validators.required);
      
    }
    else
    {
      this.form.controls.COPDataFieldMessageGroupDropDown?.clearValidators();

    }


    if (this.selectedInstruction.successPKRequired )
    {
      console.log('successpk  is now required');
      this.form.controls.SuccessNextRouteDropDown.setValidators(Validators.required);
     
    }
    else
    {
      this.form.controls.SuccessNextRouteDropDown?.clearValidators();

    }
    if (this.selectedInstruction.failedPKRequired )
    {
      console.log('failedpk  is now required');
      this.form.controls.FailedNextRouteDropDown.setValidators(Validators.required);
    
    }
    else
    {
      this.form.controls.FailedNextRouteDropDown?.clearValidators();

    }
    if (this.selectedInstruction.otherPKRequired )
    {
      console.log('otherpk is now required');

      this.form.controls.OtherNextRouteDropDown.setValidators(Validators.required);

    }
    else
    {
      this.form.controls.OtherNextRouteDropDown?.clearValidators();

    }
    if (this.selectedInstruction.alternatePKRequired )
    {
      console.log('alternatepk is now required');

      this.form.controls.AlternateNextRouteDropDown.setValidators(Validators.required);
    
    }
    else
    {
      this.form.controls.AlternateNextRouteDropDown?.clearValidators();

    }

    this.form.controls.GatherLengthInput?.updateValueAndValidity();
    //this.form.controls.RecordingDropDown?.updateValueAndValidity();
    this.form.controls.COPDataFieldMessageGroupDropDown?.updateValueAndValidity();
    this.form.controls.AlternateNextRouteDropDown?.updateValueAndValidity();
    this.form.controls.OtherNextRouteDropDown?.updateValueAndValidity();
    this.form.controls.FailedNextRouteDropDown?.updateValueAndValidity();
    this.form.controls.SuccessNextRouteDropDown?.updateValueAndValidity();



  }


submit() {


  console.log("form submitted");
  console.log(this.form);

  if (this.form.valid)
  {

    if (this.form.dirty) {
     console.log('changes detected in form');
    this.callpathroute = {} as ICallPathRoute;
    this.callpathroute.digits = [] ;
    this.callpathroute.recordings = [] ;
    
    this.callpathroute.id = this.form.controls.id?.value;
    this.callpathroute.instructionID =  this.selectedInstruction.id.toString();
    this.callpathroute.description = this.form.controls.DescriptionInput.value;
    //this.callpathroute.recordingID =  "5";
    this.callpathroute.gatherLength = this.form.controls.GatherLengthInput?.value;
    this.callpathroute.calloffportalpk = this.form.controls.COPDataFieldMessageGroupDropDown?.value;
    console.log(this.form.controls.SuccessNextRouteDropDown?.value);
    this.callpathroute.nextRouteSuccessID = this.lookupRoutebyDesc( this.form.controls.SuccessNextRouteDropDown?.value)?.id.toString();
    
    this.callpathroute.nextRouteFailureID =  this.lookupRoutebyDesc( this.form.controls.FailedNextRouteDropDown?.value)?.id.toString();
    this.callpathroute.nextRouteOtherID =  this.lookupRoutebyDesc( this.form.controls.OtherNextRouteDropDown?.value)?.id.toString();
    this.callpathroute.nextRouteAlternateID =  this.lookupRoutebyDesc( this.form.controls.AlternateNextRouteDropDown?.value)?.id.toString();

    for (let c of this.digits.controls  ) {
      var c1 = c as FormGroup;
      this.callpathroute.digits.push( {
        digit: c1.controls['digit'].value,
        ddescription: c1.controls['ddescription'].value, 
        dnextrouteid: this.lookupRoutebyDesc(c1.controls['dnextroute'].value)?.id.toString(),
        pk: c1.controls['pk'].value,
        savedValue: c1.controls['savedValue'].value,
        setLanguage: c1.controls['setLanguage'].value,
        calloffPortalDFpk: c1.controls['calloffPortalDFpk'].value
 
      });
    }

    console.log('before recording controls');
    for (let c of this.recordings.controls  ) {
      var c1 = c as FormGroup;
      this.callpathroute.recordings.push( {
        lang: c1.controls['langDropDown'].value,
        recordingID: c1.controls['RecordingDropDown'].value.pk, 
       
      });
    }

  
    console.log('after recording controls');
    console.log(this.callpathroute.recordings);
    if (this.callpathroute.digits.length > 0 )
      this.callpathroute.DigitPress = "Y";
    else
    this.callpathroute.DigitPress = "N";
    
    this.callpathroute.account = this.account.accountNumber;
    this.callpathroute.UserGUID = this.userid;
    
    console.log(this.callpathroute);
  
    if (this.routeid === 0) {
      console.log('inserting route');

      this.callpathrouteservice.createRoute(this.callpathroute)
        .subscribe({
          next: () => this.onSaveComplete(),
          error: err => this.errorMessage = err
        });
    } else {

      console.log('updating route');
      
      this.callpathrouteservice.updateRoute(this.callpathroute)
        .subscribe({
          next: () => this.onSaveComplete(),
          error: err => this.errorMessage = err
        });
    }

  
  }
  else
  {
    console.log(' no changes in form');
    this.onSaveComplete();
  }


   
  }
else
{
  this.errorMessage = 'Please correct the validation errors.';

}

  
  


}

onSaveComplete(): void {
  console.log('inside save complete');
  // Reset the form to clear the flags
  this.form.reset();
  this.router.navigate(['/callpathroutes']);
}

goback(): void
{
  this.router.navigate(['/callpathroutes']);
 
}

}
