import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Track } from 'ngx-audio-player';
import { environment } from 'src/environments/environment';
//import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';


@Component({
  selector: 'app-audio',
  templateUrl: './audio.component.html',
  styleUrls: ['./audio.component.css']
})
export class AudioComponent implements OnInit {

msaapDisplayTitle = true;
msaapDisplayPlayList = false;
msaapPageSizeOptions = [2,4,6];
msaapDisplayVolumeControls = true;
msaapDisplayRepeatControls = true;
msaapDisplayArtist = false;
msaapDisplayDuration = true;
msaapDisablePositionSlider = true;
id:string;
title: string;
// Material Style Advance Audio Player Playlist
msaapPlaylist: Track[] = [
  {
    title: '',
    link: '' ,
    artist: ''
  }
];
  constructor(public dialogRef: MatDialogRef<AudioComponent>,@Inject(MAT_DIALOG_DATA) data: any) {
//
    this.id = data.id;
    this.title= data.title;
   }

  ngOnInit(): void {

    const url:string  = environment.apiUrl +   "Recording/" + this.id;
     console.log(url);
    this.msaapPlaylist[0].link= url;
    this.msaapPlaylist[0].title= this.title;

  }

  onDoneClick(): void {
    this.dialogRef.close();
  }
}
