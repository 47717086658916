import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IVRAccount } from 'src/app/models/IVRAccount';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-processchanges',
  templateUrl: './processchanges.component.html',
  styleUrls: ['./processchanges.component.scss']
})
export class ProcesschangesComponent implements OnInit {

  errorMessage: string;
  userid : string;
  account: IVRAccount =  new IVRAccount();
  viewedchart: string;
  loading: boolean;

  constructor(   private router: Router, private state: StateService) {
    //this.account.accountNumber = this.route.snapshot.paramMap.get('accountnumber');
    //this.account.friendlyName = this.route.snapshot.paramMap.get('friendlyname');

    this.state.ivraccount.subscribe(result => {
      this.account = result; // this set's the username to the default observable value
      console.log(this.account.accountNumber);
      console.log(this.account.friendlyName);

    });

    this.state.user.subscribe(result => {
                        this.userid = result.userid
                        console.log(this.userid
                          )});


                        }
  ngOnInit(): void {
    
  this.loading = true;
   this.state.viewedchart.subscribe(result => {
    this.viewedchart = result;

    console.log(this.viewedchart          );
    
  });


  if (this.viewedchart != "Y") 
  {
   this.state.changeViewedChart("Y");

   this.router.navigate(['/viewchart',this.account.accountNumber]);
  }
 else
 {

  
   this.router.navigate(['/confirmation']);

 }


  }
  


}
