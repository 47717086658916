import { NgxLoggerLevel } from "ngx-logger";

// setting production to true changes http to https 
export const environment = {
  production: true,
  apiUrl: 'https://azapi-ivrwizard-kohler.azurewebsites.net/api/ivr/',
  clientId: '596a4238-f236-4858-9ffc-6de09b7a53ac',
  authority: 'https://login.microsoftonline.com/7801d8bd-c3ee-43a8-a040-56f89cb38318',
  redirectUri: 'https://ivrwizardkohler.continentalmessage.com',
  Apikey: 'A4E48E9C-4EA4-4BF3-8BFC-DB5F395BEB90',
  logapiUrl: '', // Replace with remote API
  logLevel: NgxLoggerLevel.DEBUG,
  serverLogLevel: NgxLoggerLevel.DEBUG,
 
  appInsights: {
    instrumentationKey: '4e4ce236-60ef-463a-adc9-37c619d5e6d2'
}
};
