<mat-toolbar color="primary">
    <img src="https://www.continentalmessage.com/wp-content/uploads/2017/11/cms-stickylogo.png" srcset="https://www.continentalmessage.com/wp-content/uploads/2017/11/cms-stickylogo.png 1x" width="209" height="45" alt="Continental Message Solution (CMS) Logo" data-retina_logo_url="" class="fusion-mobile-logo">
  
  
    <div class="toolbar-spacer"></div>
    <a  *ngIf="IsAdminUser" mat-button [routerLink]="['orderphone']" >Order Bandwidth Phone</a>
  
    <a mat-button [routerLink]="['accountselection']" >Account</a>
    
    
    <button mat-raised-button *ngIf="!loginDisplay" (click)="login()">Login</button>
    <button mat-raised-button *ngIf="loginDisplay" (click)="logout()">Logout</button>
    
    
  

  </mat-toolbar>

  <div class="right">
  <span   *ngIf="username">   Welcome  {{ username }}</span><br/>
    <span   *ngIf="this.account && this.account.accountNumber  "> {{ this.account.accountNumber }} - {{ this.account.friendlyName }}</span>
   
  </div>
   
  <div class="container">
    
    
    <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
    <router-outlet *ngIf="!isIframe"></router-outlet>
  </div>
  