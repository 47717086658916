import { analyzeAndValidateNgModules } from '@angular/compiler';
import { ChangeDetectorRef, Component, OnInit, OnChanges, ViewChild, ViewEncapsulation, AfterViewInit, ElementRef, HostListener } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import * as go from 'gojs';
import { DataSyncService, DiagramComponent, PaletteComponent } from 'gojs-angular';
import * as _ from 'lodash';
import { forkJoin } from 'rxjs';
import { IVR } from 'src/app/models/IVR';
import { ChartMode, ChartModeEnum, IVRAccount } from 'src/app/models/IVRAccount';
import { IVRNode } from 'src/app/models/Node';
import { IVRLink } from 'src/app/models/NodeLink';
import { StateService } from 'src/app/services/state.service';
import { NodeService } from '../../services/node.service';

@Component({
  selector: 'app-viewchart',
  templateUrl: './viewchart.component.html',
  styleUrls: ['./viewchart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ViewChartComponent implements OnInit, AfterViewInit{

  @ViewChild('myDiagram', { static: true }) public myDiagramComponent: DiagramComponent;
  @ViewChild('myPalette', { static: true }) public myPaletteComponent: PaletteComponent;

  
@ViewChild('scaleField', { static: true }) public scaleField: ElementRef;
@ViewChild('scaleSlider', { static: true }) public scaleSlider: ElementRef;

account: IVRAccount =   new IVRAccount();
mode: ChartMode = new ChartMode();
  
  public IVRdata: IVR;
  public diagramNodeData: Array<go.ObjectData>;
  public diagramLinkData: Array<go.ObjectData> ;

  public screenWidth: any;
  public screenHeight: any;

  public diagramDivClassName: string = 'myDiagramDiv';
  public diagramModelData = { prop: 'value' };
  public skipsDiagramUpdate = false;

  private userid : string;
  // When the diagram model changes, update app data to reflect those changes
  public diagramModelChange = function(changes: go.IncrementalData) {
    // when setting state here, be sure to set skipsDiagramUpdate: true since GoJS already has this update
    // (since this is a GoJS model changed listener event function)
    // this way, we don't log an unneeded transaction in the Diagram's undoManager history
    this.skipsDiagramUpdate = true;

    this.diagramNodeData = DataSyncService.syncNodeData(changes, this.diagramNodeData);
    this.diagramLinkData = DataSyncService.syncLinkData(changes, this.diagramLinkData);
    this.diagramModelData = DataSyncService.syncModelData(changes, this.diagramModelData);
  };


  constructor(private cdr: ChangeDetectorRef, private nodeservice: NodeService, private route: ActivatedRoute, private router: Router, private state:StateService) { 

    this.account.accountNumber = this.route.snapshot.paramMap.get('accountnumber');

    //this.account.friendlyName = this.route.snapshot.paramMap.get('friendlyname');
    console.log("in constructor of viewchart");
    
      this.IVRdata =  this.route.snapshot.data['resolvedivrnodes'];
      if (this.IVRdata.errors != null)
        console.error('errors->' + this.IVRdata.errors);
      
      this.diagramNodeData = this.IVRdata.nodes;

      this.diagramLinkData =  this.route.snapshot.data['resolvedivrlinks'];
    
      console.log(this.diagramNodeData);
       console.log(this.diagramLinkData);
     


       this.state.chartmode.subscribe(result => {
        this.mode.currentmode = result.currentmode
        console.log(this.mode          )});
     

  }
  ngOnInit(): void {
    console.log('ngoniit of viewchart');
     
  }

  goback(): void{

    
    this.state.changeViewedChart("N");

    if (this.mode.currentmode == ChartModeEnum.Edit)
      this.router.navigate(['/callpathroutes']);
    else if (   this.mode.currentmode == ChartModeEnum.Apply)
      this.router.navigate(['/viewpendingchanges']);
    else
      this.router.navigate(['/accountselectmenu']);
    
  }
 
  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //   alert('onresize');
  //   this.setScreenSize();
  // }

  setScreenSize()
  {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    
 
    // var style = `'width: ${this.screenWidth * 0.8}px; height: ${this.screenHeight * 0.8}px'`;
    
    // var style = `'width: calc(80%)'`;
    
    // alert(style);
    // console.log(this.myDiagramComponent.diagramDiv.nativeElement.style);
    // this.myDiagramComponent.diagramDiv.nativeElement.setAttribute('style', 'width: calc(80%)');
 
    

  }
  async getdata()
  {
    let nodedata: any;
    let linksdata: any;
    console.log('inside async getdata');
    console.log(this.userid);
    console.log('calling async getdata');
    
    if (this.mode.currentmode == ChartModeEnum.Edit  || this.mode.currentmode == ChartModeEnum.Apply)
       nodedata = await this.nodeservice.getnodes(this.account.accountNumber, this.userid).toPromise();
    else
      nodedata = await this.nodeservice.getnodes(this.account.accountNumber,"").toPromise();

    if (this.mode.currentmode == ChartModeEnum.Edit   || this.mode.currentmode == ChartModeEnum.Apply)
       linksdata =  await this.nodeservice.getlinks(this.account.accountNumber, this.userid).toPromise();
    else
       linksdata =  await this.nodeservice.getlinks(this.account.accountNumber,"").toPromise();

    this.diagramNodeData = nodedata;
    this.diagramLinkData = linksdata;
    console.log(this.diagramNodeData);
    console.log(this.diagramLinkData);

  }
  ngOnChanges(): void
  {
    console.log('ngonchanges');
  }


  ngAfterViewInit(): void {

    console.log("after view init");
    this.setScreenSize();
    
    if (this.observedDiagram) return;
    this.observedDiagram = this.myDiagramComponent.diagram;
    this.cdr.detectChanges(); // IMPORTANT: without this, Angular will throw ExpressionChangedAfterItHasBeenCheckedError (dev mode only)

    const appComp: ViewChartComponent = this;
    // listener for inspector
    this.myDiagramComponent.diagram.addDiagramListener('ChangedSelection', function(e) {
      if (e.diagram.selection.count === 0) {
        appComp.selectedNode = null;
      }
      const node = e.diagram.selection.first();
      if (node instanceof go.Node) {
        appComp.selectedNode = node;
      } else {
        appComp.selectedNode = null;
      }
    });

  } // end ngAfterViewInit


  // initialize diagram / templates
    public initDiagram(): go.Diagram 
    {

      go.Diagram.licenseKey  = "73f947e7ba6731b700ca0d2b113f69ed1bb37b3b9e801ff45d5541f3ef0d68442b9fed7958878e92d5f94aac1d2dc2da8ec37b399145566be737808917e484f1b23323b711094e8ba00321c599af2da7fe7122f7c7e720a0d9798ba5e3f8919b58baa3d54c990fbe28285636057cab4fa5ebdf2ea904cc1d3b6c8ab7f9e8ac48e56b6884";
    
      const $ = go.GraphObject.make;
      const dia = $(go.Diagram, {
        'undoManager.isEnabled': true,
        allowCopy: false,
        "draggingTool.dragsTree": true,
        "commandHandler.deletesTree": true,
        layout:
        $(go.TreeLayout,
          { angle: 90, arrangement: go.TreeLayout.ArrangementFixedRoots }),
        model: $(go.GraphLinksModel,
          {
            linkToPortIdProperty: 'toPort',
            linkFromPortIdProperty: 'fromPort',
            linkKeyProperty: 'key' // IMPORTANT! must be defined for merges and data sync when using GraphLinksModel
          }
        )
      });
      dia.initialAutoScale = go.Diagram.Uniform;
      dia.commandHandler.archetypeGroupData = { key: 'Group', isGroup: true };

      const makePort = function(id: string, spot: go.Spot) {
        return $(go.Shape, 'Circle',
          {
            opacity: .5,
            fill: 'gray', strokeWidth: 0, desiredSize: new go.Size(8, 8),
            portId: id, alignment: spot,
            fromLinkable: true, toLinkable: true
          }
        );
      }

    var bluegrad = $(go.Brush, "Linear", { 0: "#C4ECFF", 1: "#70D4FF" });
    var greengrad = $(go.Brush, "Linear", { 0: "#B1E2A5", 1: "#7AE060" });

     // each action is represented by a shape and some text
     var actionTemplate =
     $(go.Panel, "Horizontal",
       $(go.Shape,
         { width: 12, height: 12 },
         new go.Binding("figure"),
         new go.Binding("fill")
       ),
       $(go.TextBlock,
         { font: "10pt Verdana, sans-serif" },
         new go.Binding("text")
       )
     );


      dia.nodeTemplate =  // the default node template
      $(go.Node, "Vertical",
      new go.Binding("isTreeExpanded").makeTwoWay(),  // remember the expansion state for
      new go.Binding("wasTreeExpanded").makeTwoWay(), //   when the model is re-loaded
      { selectionObjectName: "BODY" },
      // the main "BODY" consists of a RoundedRectangle surrounding nested Panels
      $(go.Panel, "Auto",
        { name: "BODY" },
        $(go.Shape, "Rectangle",
          { fill: bluegrad, stroke: null }
        ),
        $(go.Panel, "Vertical",
          { margin: 3 },
          // the title
          $(go.TextBlock,
            {
              stretch: go.GraphObject.Horizontal,
              font: "bold 12pt Verdana, sans-serif"
            },
            new go.Binding("text", "question")
          ),
          // the optional list of actions
          $(go.Panel, "Vertical",
            { stretch: go.GraphObject.Horizontal, visible: false },  // not visible unless there is more than one action
            new go.Binding("visible", "actions", function(acts) {
              return (Array.isArray(acts) && acts.length > 0);
            }),
            // headered by a label and a PanelExpanderButton inside a Table
            $(go.Panel, "Table",
              { stretch: go.GraphObject.Horizontal },
              $(go.TextBlock, "Choices",
                {
                  alignment: go.Spot.Left,
                  font: "10pt Verdana, sans-serif"
                }
              ),
              $("PanelExpanderButton", "COLLAPSIBLE",  // name of the object to make visible or invisible
                { column: 1, alignment: go.Spot.Right }
              )
            ), // end Table panel
            // with the list data bound in the Vertical Panel
            $(go.Panel, "Vertical",
              {
                name: "COLLAPSIBLE",  // identify to the PanelExpanderButton
                padding: 2,
                stretch: go.GraphObject.Horizontal,  // take up whole available width
                background: "white",  // to distinguish from the node's body
                defaultAlignment: go.Spot.Left,  // thus no need to specify alignment on each element
                itemTemplate: actionTemplate  // the Panel created for each item in Panel.itemArray
              },
              new go.Binding("itemArray", "actions")  // bind Panel.itemArray to nodedata.actions
            )  // end action list Vertical Panel
          )  // end optional Vertical Panel
        )  // end outer Vertical Panel
      ),  // end "BODY"  Auto Panel
      $(go.Panel,  // this is underneath the "BODY"
        { height: 17 },  // always this height, even if the TreeExpanderButton is not visible
        $("TreeExpanderButton")
      ),
      makePort('b', go.Spot.BottomCenter)
    );

    // define a second kind of Node:
    dia.nodeTemplateMap.add("Terminal",
    $(go.Node, "Spot",
      $(go.Shape, "Circle",
        { width: 55, height: 55, fill: greengrad, stroke: null }
      ),
      $(go.TextBlock,
        { font: "10pt Verdana, sans-serif" },
        new go.Binding("text")
      )
    )
    );
    

    return dia;
  }

  
handleScaleKeyPress(e) {
  if (e.keyCode !== 13) return;
  e.preventDefault();
  this.doScale();

};


doScale() {
  console.log('scalefield value' + this.scaleField.nativeElement.value);
  if (this.scaleField.nativeElement.value) {
   this.myDiagramComponent.diagram.scaleComputation = null;
     this.scaleSlider.nativeElement.value = this.myDiagramComponent.diagram.scale = parseFloat(this.scaleField.nativeElement.value);
     console.log('scaleslider value' + this.scaleSlider.nativeElement.value);
     this.myDiagramComponent.diagram.scaleComputation = this.scalefunc;
 }

};

doAutoFit() {
 this.myDiagramComponent.diagram.scaleComputation = null;
 this.myDiagramComponent.diagram.zoomToFit();
 this.myDiagramComponent.diagram.scaleComputation = this.scalefunc;
};

scaleSliderChange(slidervalue)
{
 console.log('inside scalesliderchage' + slidervalue);
 this.myDiagramComponent.diagram.scale = parseFloat(slidervalue);
 this.scaleField.nativeElement.valueAsNumber = this.myDiagramComponent.diagram.scale ;

}


scalefunc(diagram, scale) {
 const oldscale = diagram.scale;
 console.log('old scale is' + oldscale);
 if (scale > oldscale) {
     return oldscale + 0.25;
 } else if (scale < oldscale) {
     return oldscale - 0.25;
 }
 return oldscale;

};

doDownloadImage() {
  const blob = this.myDiagramComponent.diagram.makeImageData({ background: "white", returnType: "blob", callback: this.completeDownload.bind(this) });
}
 appendLeadingZeroes(n) {
  return n <= 9 ? `0${n}` : n;
}

 getFormattedDateTime() {
  const dt = new Date();
  return dt.getFullYear() +
      "-" +
      this.appendLeadingZeroes(dt.getMonth() + 1) +
      "-" +
      this.appendLeadingZeroes(dt.getDate()) +
      " " +
      this.appendLeadingZeroes(dt.getHours()) +
      ":" +
      this.appendLeadingZeroes(dt.getMinutes()) +
      ":" +
      this.appendLeadingZeroes(dt.getSeconds());
}

 

// When the blob is complete, make an anchor tag for it and use the tag to initiate a download
// Works in Chrome, Firefox, Safari, Edge, IE11
completeDownload(blob) {
  var url = window.URL.createObjectURL(blob);
  const filename = `IVR_Tree-${this.getFormattedDateTime()}.png`;
  var a = document.createElement("a");
  //a.style = "display: none";
  a.href = url;
  a.download = filename;
  // For IE 11


  if (window.navigator.msSaveBlob !== undefined) {
      window.navigator.msSaveBlob(blob, filename);
      return;
  }
  document.body.appendChild(a);
  requestAnimationFrame(function () {
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
  });

}


  public initPalette(): go.Palette {
    const $ = go.GraphObject.make;
    const palette = $(go.Palette);

    // define the Node template
    palette.nodeTemplate =
      $(go.Node, 'Auto',
        $(go.Shape, 'RoundedRectangle',
          {
            stroke: null
          },
          new go.Binding('fill', 'color')
        ),
        $(go.TextBlock, { margin: 8 },
          new go.Binding('text'))
      );

    palette.model = $(go.GraphLinksModel,
      {
        linkKeyProperty: 'key'  // IMPORTANT! must be defined for merges and data sync when using GraphLinksModel
      });

    return palette;
  }
  public paletteNodeData: Array<go.ObjectData> = [
    { key: 'PaletteNode1', text: "PaletteNode1", color: 'red' },
    { key: 'PaletteNode2', text: "PaletteNode2", color: 'yellow' }
  ];
  public paletteLinkData: Array<go.ObjectData> = [
    {  }
  ];
  public paletteModelData = { prop: 'val' };
  public paletteDivClassName = 'myPaletteDiv';
  public skipsPaletteUpdate = false;
  public paletteModelChange = function(changes: go.IncrementalData) {
    // when setting state here, be sure to set skipsPaletteUpdate: true since GoJS already has this update
    // (since this is a GoJS model changed listener event function)
    // this way, we don't log an unneeded transaction in the Palette's undoManager history
    this.skipsPaletteUpdate = true;

    this.paletteNodeData = DataSyncService.syncNodeData(changes, this.paletteNodeData);
    this.paletteLinkData = DataSyncService.syncLinkData(changes, this.paletteLinkData);
    this.paletteModelData = DataSyncService.syncModelData(changes, this.paletteModelData);
  };

  // Overview Component testing
  public oDivClassName = 'myOverviewDiv';
  public initOverview(): go.Overview {
    const $ = go.GraphObject.make;
    const overview = $(go.Overview);
    return overview;
  }
  public observedDiagram = null;

  // currently selected node; for inspector
  public selectedNode: go.Node | null = null;


  public handleInspectorChange(newNodeData) {
    const key = newNodeData.key;
    // find the entry in nodeDataArray with this key, replace it with newNodeData
    let index = null;
    for (let i = 0; i < this.diagramNodeData.length; i++) {
      const entry = this.diagramNodeData[i];
      if (entry.key && entry.key === key) {
        index = i;
      }
    }

    if (index >= 0) {
      // here, we set skipsDiagramUpdate to false, since GoJS does not yet have this update
      this.skipsDiagramUpdate = false;
      this.diagramNodeData[index] = _.cloneDeep(newNodeData);
      // this.diagramNodeData[index] = _.cloneDeep(newNodeData);
    }

    // var nd = this.observedDiagram.model.findNodeDataForKey(newNodeData.key);
    // console.log(nd);

  }


}

