import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { IVRAccount } from '../models/IVRAccount';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root'
})
export class AccountResolverService implements Resolve<IVRAccount[]> {

  constructor(private accountService: AccountService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IVRAccount[]> {
    
     return  this.accountService.getaccounts();
    
  }
  
}
