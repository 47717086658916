import { IVRAccountLanguage } from "./IVRAccountLanguage";

export class IVRAccount
{
    accountNumber: string;
    friendlyName: string;
    languages: IVRAccountLanguage[];
    cprpk?: number;
    calloffportalpk?: number;
    timezoneoffset? : number;
    display: string;

    
}

export class ChartMode{
    currentmode: ChartModeEnum ;

}

export enum ChartModeEnum
{
    View = 0,
    Edit = 1,
    Apply = 2
}