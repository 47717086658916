import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { MyMonitoringService } from './my-monitoring-service.service';
import { NGXLogger } from 'ngx-logger';
import { Phone } from '../models/Phone';
import { Order, OrderResponse } from '../models/Order';
import { Application } from '../models/Application';
import { IVRAccount } from '../models/IVRAccount';

@Injectable({
  providedIn: 'root'
})
export class BandwidthService {

   httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      Authorization: 'my-auth-token',
      'Apikey': environment.Apikey
    })
  };
  
  constructor(private http: HttpClient, private logger: NGXLogger,
    private myMonitoringService: MyMonitoringService) { }

  showavailableNumbers(): Observable<Phone[]>
  {

    const url:string  = environment.apiUrl + "BandwidthSearchPhone";
    this.logger.log(url);
    return this.http.get<Phone[]>(url,this.httpOptions).pipe(
      tap(_ => this.logger.log('fetched available numbers')),
      catchError( this.handleError<Phone[]>('searchphones', []))
    );
     
  }

  getapplications(): Observable<Application[]>
  {

    const url:string  = environment.apiUrl + "BandwidthApplications";
    this.logger.log(url);
    return this.http.get<Application[]>(url,this.httpOptions).pipe(
      tap(_ => this.logger.log('fetched available applications')),
      catchError( this.handleError<Application[]>('getapplications', []))
    );
     
  }

  getaccounts(): Observable<IVRAccount[]>
  {

    const url:string  = environment.apiUrl + "BandwidthAccountMenu";
    this.logger.log(url);
    return this.http.get<IVRAccount[]>(url,this.httpOptions).pipe(
      tap(_ => this.logger.log('fetched available accounts')),
      catchError( this.handleError<IVRAccount[]>('getaccounts', []))
    );
     
  }

  orderphone(phoneorder: Order): Observable<OrderResponse> {

    const url:string  = environment.apiUrl + "BandwidthOrderPhone";
   
     
     console.log(JSON.stringify(phoneorder));
     return this.http.post<OrderResponse>(url, JSON.stringify(phoneorder), this.httpOptions)
       .pipe(
         tap(data => console.log('orderphone : ' + JSON.stringify(data))),
         catchError(this.handleError)
       );
   }
 
 checkorderstatus(orderid: string) : Observable<Order>
 {

  const url:string  = environment.apiUrl + "BandwidthOrderPhoneStatus?id=" + orderid ;
  this.logger.log(url);
  return this.http.get<Order>(url,this.httpOptions).pipe(
    tap(_ => this.logger.log('fetch order status')),
    catchError( this.handleError<Order>('checkorderstatus'))
  );


 }
   sendtext(phoneorder: Order): Observable<string> {

    const url:string  = environment.apiUrl + "BandwidthSendText";
  
    console.log(JSON.stringify(phoneorder));
    return this.http.post<string>(url, JSON.stringify(phoneorder), this.httpOptions)
      .pipe(
        tap(data => console.log('sendtext : ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }



    private handleError<T>(operation = 'operation', result?: T){
      return (error: any): Observable<T> => {
    

        this.myMonitoringService.logException(error);
        // TODO: send the error to remote logging infrastructure
        this.logger.error(error); // log to console instead
    
        // TODO: better job of transforming error for user consumption
        this.logger.log(`${operation} failed: ${error.message}`);
    
        // Let the app keep running by returning an empty result.
        return of(result as T);
      };
      }
 }


