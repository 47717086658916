
<div  *ngIf="loading">
    Please wait while we load your data....
    <mat-spinner [diameter]="60"></mat-spinner>
  </div>
  
  <div *ngIf="!loading"><fieldset>
      <legend>  Pick the recording that you would like to use across accounts</legend>
  
  
      <div class="message">{{infoMessage}}</div> 
      <form  [formGroup]="form" (ngSubmit)="OnSubmit()">
    
      <div class="form-container">
        
        <div class="flex-item" >
    
          <mat-form-field appearance="outline">
              <mat-label>Pick the language </mat-label>
              <mat-select formControlName ="LanguagesDropDown" (selectionChange)="changeRecordings($event.value)">
                  <mat-option *ngFor="let rec of this.languages"
                  [value]="rec.language">{{rec.displayLanguage}}</mat-option>
              </mat-select>
            </mat-form-field>
  
        </div>
        <div class="flex-item-wide" >
    
                <mat-form-field appearance="outline" >
                  <mat-label>Type recording transcription text or recording id and then click down arrow to see all matching results</mat-label>
                  <input type="text" matInput formControlName ="RecordingDropDown" [matAutocomplete]="RecordingDropDown">
                  <mat-autocomplete autoActiveFirstOption #RecordingDropDown="matAutocomplete" >
      
                      <mat-option (onSelectionChange)="setRecording(rec)"  *ngFor="let rec of this.filteredrecordings | async"
                      [value]="rec.display">{{rec.display}}</mat-option>
                  </mat-autocomplete>
                </mat-form-field>
    
        </div>
    
        <span class="flex-item-wide-smallfont" *ngIf="this.selectedRecording">
                <em>You selected: </em>{{ this.selectedRecording.display }}
        </span>
              
              
        <div class="flex-item3">
        
              <button mat-raised-button color="primary" (click)="goback()" >Cancel</button>
  
              <button mat-raised-button color="primary" type="submit"  >Delete  Recording</button>
  
          </div>
  
      </div>
        </form>
    </fieldset>
    
    <div class="message"
    *ngIf="errorMessage">{{errorMessage}}
    </div>
           
            </div>
  
    
      
          
           
           