

<div class="center">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Are you sure you want to delete the following Route?

        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-list-item> 
          ID: <strong>{{ routeToBeDeleted.id }}</strong>
        </mat-list-item>
        <mat-list-item>
          Description: <strong>{{ routeToBeDeleted.description }}</strong> 
        </mat-list-item>
        

      </mat-card-content>
    
      <mat-card-actions>
        
        <button mat-stroked-button color="primary" (click)="onCloseConfirm()">CONFIRM</button>&nbsp;
        <button mat-stroked-button color="primary" (click)="onCloseCancel()">CANCEL</button>

      </mat-card-actions>
    
    </mat-card>
    </div>
    
    