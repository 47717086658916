import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChartMode, ChartModeEnum, IVRAccount } from 'src/app/models/IVRAccount';
import { PendingCount } from 'src/app/models/PendingCount';
import { AccountService } from 'src/app/services/account.service';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-viewpendingchanges',
  templateUrl: './viewpendingchanges.component.html',
  styleUrls: ['./viewpendingchanges.component.scss']
})
export class ViewpendingchangesComponent implements OnInit {

  errorMessage: string;
  userid : string;
  account: IVRAccount =  new IVRAccount();
  pendingcounts: PendingCount;
  viewedchart: string;

  constructor( private  accountservice: AccountService, private route: ActivatedRoute,  private router: Router, private state: StateService) {
    //this.account.accountNumber = this.route.snapshot.paramMap.get('accountnumber');
    //this.account.friendlyName = this.route.snapshot.paramMap.get('friendlyname');

    this.state.ivraccount.subscribe(result => {
      this.account = result; // this set's the username to the default observable value
      console.log(this.account.accountNumber);
      console.log(this.account.friendlyName);

    });

    this.state.user.subscribe(result => {
                        this.userid = result.userid
                        console.log(this.userid
                          )});


                        }
  ngOnInit(): void {
    console.log('ngoninit of viewpendingchanges called');
    this.accountservice.getPendingCounts(this.account.accountNumber, this.userid).subscribe({
     next: counts => {
       console.log(counts);
       this.pendingcounts = counts;
     },
     error: err => this.errorMessage = err
   });


   this.state.viewedchart.subscribe(result => {
    this.viewedchart = result;

    console.log(this.viewedchart          );
    
  });


  }
  goback()
  {
    
    this.router.navigate(['/accountselectmenu']);
  }

  Continue()
  {
   
    if (this.pendingcounts.totals > 0)
      this.router.navigate(['/processchanges']);
    else
      this.errorMessage = "There are no changes to be applied. If you need to change the first instruction for this account, you can update under Manage Account Settings."

  }
}
