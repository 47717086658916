<div *ngIf="loading">
    Please wait while we load your data....
    <mat-spinner [diameter]="60"></mat-spinner>
  </div>
  <div *ngIf="!loading">
    <fieldset>
      <legend>{{ pageTitle }}</legend>
      <form [formGroup]="form" (ngSubmit)="submit()">
        <div class="form-container">
          <div class="flex-item2">


            <mat-form-field >
                <mat-label>Select Account (if the account is 'new' pick 'Other')</mat-label>
                <input type="text"
                       placeholder="Pick account, if the account is new pick Other"
                       aria-label="Select Account"
                       matInput
                       formControlName="AccountTypeDropDown"
                       [matAutocomplete]="AccountTypeDropDown"
       
                       >
                <mat-autocomplete autoActiveFirstOption #AccountTypeDropDown="matAutocomplete">
                  <mat-option *ngFor="let account of filteredAccounts | async" 
                  [value]="account.display" (onSelectionChange)="setaccount(account)">
                    {{account.display}} </mat-option>
                </mat-autocomplete>

                <mat-error
                *ngIf="
                  this.form.controls.AccountTypeDropDown.hasError('required')
                "
              >
                Account  is <strong>required</strong>
              </mat-error>

              </mat-form-field>
          
  
          </div>
          <span class="flex-item-wide-smallfont" *ngIf="this.selectedAccount">
            <em>You selected: </em>{{ this.selectedAccount.friendlyName }}
          </span>
  
          <div class="flex-item2" *ngIf="this.selectedAccount.accountNumber == '-1'">
            <mat-form-field appearance="outline">
              <mat-label>Account Name</mat-label>
              <textarea
                matInput
                formControlName="AccountNameInput"
                placeholder="Enter name for this new account"
                required
              ></textarea>
              <mat-error
              *ngIf="
                this.form.controls.AccountNameInput.hasError('required')
              "
            >
              Account Name is <strong>required</strong>
            </mat-error>
            </mat-form-field>
          </div>
  

          <div class="flex-item2">
            <mat-form-field appearance="outline">
              <mat-label>Location</mat-label>
              <textarea
                matInput
                formControlName="LocationInput"
                placeholder="Enter description for this location"
                required
              ></textarea>
              <mat-error
                *ngIf="this.form.controls.LocationInput.hasError('required')"
              >
                Location is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
  
         
          <div
            class="flex-item2"
          >
            <mat-form-field appearance="outline">
              <mat-label
                >Select the application for this order. Unless you know specifically or instructed by development, pick SMS
              </mat-label>
              <mat-select   (selectionChange)="setApplicationId($event.value)"
                formControlName="ApplicationidDropDown"
                            >
                <mat-option
                  *ngFor="let rec of this.applications"
                  [value]="rec.id"
                  >{{ rec.name }}</mat-option
                >
              </mat-select>
            </mat-form-field>

            <button *ngIf="showAvailableNumbersButton()"
            mat-raised-button
            color="primary"
            (click)="showavailableNumbers()">

          
            Show Available Phone Numbers           </button>
     
          </div>
  
     <!--      <div class="flex-item" >
            <mat-form-field appearance="outline">
              <mat-label>Enter Phone Filter e.g. 8** must be 3 digits for Toll free if you need phones starting from specific numbers </mat-label>

              <input matInput type="text"  maxlength="3" formControlName="PhoneFilter" value="8**" >

              
            </mat-form-field>

          
          </div>
      -->  
      
      <div class="flex-item" *ngIf="showOrderPhoneButton()">
            
          

        <button
          mat-raised-button
          color="primary"
          type="submit">

        
          Order Phone {{ this.selectedPhone.number   | phoneFormat }}  
            </button>
      </div>

    
      <div class="warningmessage" *ngIf="errorMessage">{{ errorMessage }}</div>

    <div class="flex-item-grid">     
          <fieldset *ngIf="this.rowData"> 
            <legend>Phone numbers available for this account (You must select one to be able to Order Phone)</legend>

          <ag-grid-angular
          #agGrid
          pagination
          style= "width: 100%; height: 300px;"
              class="ag-theme-balham"
              [rowData]="rowData"
              [columnDefs]="columnDefs"
              [rowSelection]="single"
              (selectionChanged)="onSelectionChanged($event)"
          >
          </ag-grid-angular>

          <span class="flex-item-wide-smallfont" *ngIf="this.selectedPhone">
            <em>You selected: </em>{{ this.selectedPhone.number | phoneFormat }}
          </span>
  
          
          </fieldset>
        </div>


        </div>


       

      </form>
    </fieldset>
  


  </div>
  