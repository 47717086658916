
<div >
  Select a Node to see the values
</div>
<!--
<mat-form-field appearance="fill">
  <mat-label>Key:</mat-label>
  <mat-label>{{ data.key }}</mat-label>
</mat-form-field>
<mat-form-field appearance="fill">
  <mat-label>Text:</mat-label>
  <mat-label>{{ data.text }}</mat-label>
</mat-form-field>

<mat-form-field appearance="fill">
  <mat-label>Transcription</mat-label>
  <textarea matInput disabled>{{ data.transcription }}</textarea>
</mat-form-field>
<mat-form-field appearance="fill">
  <mat-label>Say:</mat-label>
  <mat-label>{{ data.say }}</mat-label>
</mat-form-field>
<mat-form-field appearance="fill">
  <mat-label>RecordingKey:</mat-label>
  <mat-label>{{ data.recordingkey }}</mat-label>
</mat-form-field>
<button mat-button color="primary" (click)="openDialog()">Play</button>
-->
<form>

  <div class="form-style-2">
    <div class="form-style-2-heading">Select a node to see detail</div>
    <form action="" method="post">
    <label for="key"><span>Key</span><input type="text" class="input-field" name="key" disabled value="{{data.key}}" /></label>
    <label for="cPpk"><span>Call Path Key</span><input type="text" class="input-field" name="cPpk" disabled value="{{data.cPpk}}" /></label>
    
    <label for="question"><span>Question </span><textarea class="input-field" disabled name="question" value="{{data.question}}" ></textarea></label>
    <label *ngIf='data.instructionkey != 0' for="instructionkey"><span>Instruction Key </span><input type="text"  disabled  class="input-field" name="instructionkey" value="{{data.instructionkey}}" /></label>
    <label *ngIf='data.gatherlength != null' for="gatherlength"><span>Gather Length </span><input type="text"  disabled  class="input-field" name="gatherlength" value="{{data.gatherlength}}" /></label>
    
    <label *ngIf='data.recordingkey != 0' for="transcription"><span>Transcription</span><textarea  disabled  name="transcription" class="textarea-field" value="{{data.transcription}}"></textarea></label>
    <label *ngIf='data.say != null' for="say"><span>Say </span><textarea class="input-field" disabled name="say" value="{{data.say}}" ></textarea></label>
    <label *ngIf='data.recordingkey != 0' for="recordingkey"><span>Recording Key </span><input type="text"  disabled  class="input-field" name="recordingkey" value="{{data.recordingkey}}" /></label>
    
    <label *ngIf='data.successCPpk != null' for="successCPpk"><span>Success CallPath Key </span><input type="text"  disabled  class="input-field" name="successCPpk" value="{{data.successCPpk}}" /></label>
    <label *ngIf='data.failureCPpk != null' for="failureCPpk"><span>Failure CallPath Key </span><input type="text"  disabled  class="input-field" name="failureCPpk" value="{{data.failureCPpk}}" /></label>
    <label *ngIf='data.otherCPpk != null' for="otherCPpk"><span>Other CallPath Key </span><input type="text"  disabled  class="input-field" name="otherCPpk" value="{{data.otherCPpk}}" /></label>
    <label *ngIf='data.alternateCPpk != null' for="alternateCPpk"><span>Alternate CallPath Key </span><input type="text"  disabled  class="input-field" name="alternateCPpk" value="{{data.alternateCPpk}}" /></label>
    <label *ngIf='data.dpk != null' for="dpk"><span>Digit CallPath Key </span><input type="text"  disabled  class="input-field" name="dpk" value="{{data.dpk}}" /></label>
    <label *ngIf='data.callofportaldpk != null' for="callofportaldpk"><span>Calloffportal Key </span><input type="text"  disabled  class="input-field" name="callofportaldpk" value="{{data.callofportaldpk}}" /></label>
    

    <button  mat-raised-button color="primary" (click)="openDialog()" type="submit" [disabled] = "!this.data.recordingkey"  >Play</button>
       
    <button  *ngIf='mode.currentmode == 1 ' mat-raised-button color="primary" (click)="gotoEditInstruction()" type="submit" [disabled] = "!this.data.key"  >Edit Instruction</button>
    
    <button  *ngIf='viewedchart == "Y" ' mat-raised-button color="primary" (click)="gotoViewPendingChanges()" type="submit"   >Continue</button>
    
    </form>
    </div>

    

</form>
