import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IVRAccount } from 'src/app/models/IVRAccount';
import { IVRConfirmation } from 'src/app/models/IVRConfirmation';
import { AccountService } from 'src/app/services/account.service';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

  confirmationnumber: string;
  phone: string;
  loading: boolean;
  errorMessage: string;
  
  userid : string;
  account: IVRAccount =  new IVRAccount();

  constructor( private  accountservice: AccountService, private router: Router, private state: StateService) {

    this.state.ivraccount.subscribe(result => {
      this.account = result; // this set's the username to the default observable value
      console.log(this.account.accountNumber);
      console.log(this.account.friendlyName);

    });

    this.state.user.subscribe(result => {
                        this.userid = result.userid
                        console.log(this.userid
                          )});

  }

  

  ngOnInit(): void {

    this.loading = true;
    this.accountservice.applyWizardChanges( this.account.accountNumber,this.userid)
    .subscribe(
      (resp: IVRConfirmation) => this.handleDeleteStagingResponse(resp),
      err => {this.errorMessage = err;console.log(err); this.loading=false;}
    );
    


  }

goback()
{
  
  this.router.navigate(['/accountselectmenu']);
}

  private handleDeleteStagingResponse(resp: IVRConfirmation) {
    this.loading= false;
    console.log('handle delete staging');
    console.log(resp);
    
      if (resp.errorMessage.length > 0)
      {
        console.error(resp.errorMessage);
        this.errorMessage = resp.errorMessage;
        return;
      }
     
      this.confirmationnumber = resp.confirmationNumber;
      this.phone = resp.phone;

    

  }


}
