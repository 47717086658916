import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppComponent } from '../app.component';
import { AccountSelectComponent } from '../components/accountselect/accountselect.component';
import { PageNotFoundComponent } from '../components/page-not-found/page-not-found.component';
import { ViewChartComponent } from '../components/viewchart/viewchart.component';
import { LinkResolverService } from '../services/link-resolver.service';
import { NodeResolverService } from '../services/node-resolver.service';
import { AccountResolverService } from '../services/account-resolver.service';
import { MsalGuard } from '@azure/msal-angular';
import { HomeComponent } from '../components/home/home.component';
import { AccountselectmenuComponent } from '../components/accountselectmenu/accountselectmenu.component';
import { AccountpendingchangesComponent } from '../components/accountpendingchanges/accountpendingchanges.component';
import { SelectrecordingComponent } from '../components/selectrecording/selectrecording.component';
import { AddCommonRecordingComponent } from '../components/addcommonrecording/addcommonrecording.component';
import { UploadrecordingComponent } from '../components/uploadrecording/uploadrecording.component';
import { UpdaterecordingComponent } from '../components/updaterecording/updaterecording.component';
import { DeleterecordingComponent } from '../components/deleterecording/deleterecording.component';
import { ViewpendingchangesComponent } from '../components/viewpendingchanges/viewpendingchanges.component';
import { ConfirmationComponent } from '../components/confirmation/confirmation.component';
import { ProcesschangesComponent } from '../components/processchanges/processchanges.component';
import { AccountsettingsComponent } from '../components/accountsettings/accountsettings.component';
import { OrderphoneComponent } from '../components/orderphone/orderphone.component';
import { OrderphoneconfirmationComponent } from '../components/orderphoneconfirmation/orderphoneconfirmation.component';


const ROUTES = [
 
   { path: 'accountselection', component: AccountSelectComponent, resolve: {resolveaccountsnodes: AccountResolverService},
      canActivate: [
                      MsalGuard
                  ]},
  { path: 'orderphone', component: OrderphoneComponent,
                  canActivate: [
                                  MsalGuard
                              ]},
  { path: 'orderphoneconfirmation', component: OrderphoneconfirmationComponent,
                              canActivate: [
                                              MsalGuard
                                          ]},
                          
    { path: 'viewchart/:accountnumber', component: ViewChartComponent , 
      resolve: {resolvedivrnodes: NodeResolverService, resolvedivrlinks: LinkResolverService },
        canActivate: [
                MsalGuard
                ]},
    { path: 'accountselectmenu', component: AccountselectmenuComponent , 
                  canActivate: [
                          MsalGuard
                          ]},
   { path: 'accountpendingchanges', component: AccountpendingchangesComponent , 
                          canActivate: [
                                  MsalGuard
                                  ]},
{ path: 'viewpendingchanges', component: ViewpendingchangesComponent , 
                                  canActivate: [
                                  MsalGuard
                   ]},
 { path: 'processchanges', component: ProcesschangesComponent , 
                   canActivate: [
                   MsalGuard
    ]},                                                

{ path: 'confirmation', component: ConfirmationComponent , 
                                                     canActivate: [
                                                     MsalGuard
                                      ]},                                                
                   

  { path: 'selectrecording', component: SelectrecordingComponent , 
                                  canActivate: [
                                          MsalGuard
                                          ]},
  { path: 'uploadrecording', component: UploadrecordingComponent , 
                      canActivate: [
                                                  MsalGuard
                                                  ]},
  { path: 'updaterecording', component: UpdaterecordingComponent , 
                      canActivate: [
                                                  MsalGuard
                                                  ]},
   { path: 'deleterecording', component: DeleterecordingComponent , 
                canActivate: [
                MsalGuard
 ]},
                              
                              
  { path: 'addcommonrecording', component: AddCommonRecordingComponent , 
                      canActivate: [
                                                          MsalGuard
                                                          ]},
                                
{ path: 'accountsettings', component: AccountsettingsComponent , 
canActivate: [
                                    MsalGuard
                                    ]},
                                            
                    
    // Needed for hash routing
   {  path: 'code', component: HomeComponent },
   { path: '',component: HomeComponent  },
   { path: '**', component: PageNotFoundComponent }
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(ROUTES, {
    useHash: true,
    // Don't perform initial navigation in iframes
    initialNavigation: !isIframe ? 'enabled' : 'disabled'
    
  })

],
  exports: [RouterModule]
})
export class AppRoutingModule { }

