import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NGXLogger } from 'ngx-logger';

import { forkJoin, Observable, Subscription } from 'rxjs';
import { debounceTime, map, startWith, tap } from 'rxjs/operators';
import { ICallPathRoute } from 'src/app/callpathroute/callpathroute';
import { CalloffportalDropDown } from 'src/app/models/CalloffportalDropdown';
import { Instruction } from 'src/app/models/Instruction';
import { IVRAccount } from 'src/app/models/IVRAccount';
import { IVRNextRoute } from 'src/app/models/IVRNextRoute';
import { Language } from 'src/app/models/Language';
import { Timezone } from 'src/app/models/Timezone';
import { AccountService } from 'src/app/services/account.service';
import { DropdownService } from 'src/app/services/dropdown.service';
import { StateService } from 'src/app/services/state.service';
@Component({
  selector: 'app-accountsettings',
  templateUrl: './accountsettings.component.html',
  styleUrls: ['./accountsettings.component.scss']
})
export class AccountsettingsComponent implements OnInit {

  pageTitle = 'Account Settings';
  errorMessage: string;

  
  private sub: Subscription;
  loading: boolean;

  form: FormGroup;
  timezones : Timezone[];
  filteredtimezones : Observable<Timezone[]>;
  calloffportalkeys : CalloffportalDropDown[];
  filteredcalloffportalkeys : Observable<CalloffportalDropDown[]>;
  incompletesettings: boolean;
  
  languages : Language[];
  filteredlanguages:  Observable<Language[]>;

  routes : IVRNextRoute[];
  filteredroutes:  Observable<IVRNextRoute[]>;

  userid : string;  
  selectedTimezone?: Timezone;
  selectedCallOffPortal?: CalloffportalDropDown;
  selectedLanguage?: Language;
  selectedRoute?: IVRNextRoute;

  //selectedRecording: RecordingDropDown;
  account: IVRAccount = new IVRAccount();
  accountsettings: IVRAccount;
  
  dropdownSettings: IDropdownSettings = {};

  getErrorMessage() {
    if (this.form.controls.description.hasError('required')) {
      return 'You must enter a value';
    }

  }

  constructor(private  accountservice: AccountService, private fb: FormBuilder, private dropdownService: DropdownService,
     private state: StateService, private route: ActivatedRoute,
     private router: Router , private logger: NGXLogger) {

                
    this.state.ivraccount.subscribe(result => {
      this.account = result; // this set's the username to the default observable value
      console.log(this.account.accountNumber);
      console.log(this.account.friendlyName);

    });

    this.state.user.subscribe(result => {
                        this.userid = result.userid
                        console.log(this.userid
                          )});

   console.log('inside create form');
   console.log(this.account.languages);
    
   this.createForm();
                  
    
  }


  private createForm() {

    /*this.dropdownList = [
      { item_id: 1, item_text: 'Mumbai' },
      { item_id: 2, item_text: 'Bangaluru' },
      { item_id: 3, item_text: 'Pune' },
      { item_id: 4, item_text: 'Navsari' },
      { item_id: 5, item_text: 'New Delhi' }
    ];
    */

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'displayLanguage',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
      allowSearchFilter: true
    };



    this.loading = true;
    this.form = this.fb.group({
      id: [''],
      TimezoneDropDown: ['', Validators.required],
      CallOffPortalDropDown: ['', Validators.required],
      RoutesDropDown: [''],
      MultiSelectLang: ['', Validators.required]
    });

    
  }

  
  ngOnInit(): void {
    console.log('inside ngoninit');

    
    
    if (this.account.calloffportalpk && this.account.timezoneoffset != null && this.account.languages.length > 0)
     this.incompletesettings = false;
    else
      this.incompletesettings =true ;
    
    
      forkJoin( [
        this.dropdownService.getTimeZones().pipe(tap((result) => this.timezones = result)),
        this.dropdownService.getLanguages().pipe(tap((result) => this.languages = result)),
        this.dropdownService.getCalloffPortalValues().pipe(tap((result) => this.calloffportalkeys = result)),
        this.dropdownService.getNextRoutesDropDown(this.account.accountNumber, "").pipe(tap((result) => this.routes= result)),
        
        
    ])
    .subscribe(([ ,]) => {
      console.log(this.timezones);
      console.log(this.languages);
      console.log(this.calloffportalkeys);
      console.log(this.routes);

      this.loading= false;
      this.filteredtimezones =  this.form.controls.TimezoneDropDown.valueChanges
      .pipe(
        startWith(''),

        map(value => this._filterInstruction(value))
      );
      
    this.filteredcalloffportalkeys =  this.form.controls.CallOffPortalDropDown.valueChanges
      .pipe(
        startWith(''),

        map(value => this._filterCallOffPortalKey(value))
      );
      
   /* this.filteredlanguages=  this.form.controls.LanguagesDropDown.valueChanges
      .pipe(
        startWith(''),

        map(value => this._filterLanguage(value))
      );
    */
      this.filteredroutes =  this.form.controls.RoutesDropDown.valueChanges
      .pipe(
        startWith(''),

        map(value => this._filterRoute(value))
      );
    
      console.log("before calling dislay");
      this.display(this.account);
      console.log("after calling dislay");
      
     
  }); 


}
   
  
onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  
  display(r: IVRAccount): void {
    console.log('inside display');
    
    
   

    if (this.form) {
      console.log('resetting form');
      this.form.reset();
      this.selectedTimezone = null;
      this.selectedCallOffPortal = null;
      
    }
    
    this.pageTitle = `Account Settings: ${r?.accountNumber} `;

    console.log('loading form data now');
    console.log(r);
      
    const tzdesc  = this.lookupTimezoneByID(r.timezoneoffset)?.name;
   // const tz = this.lookupTimezoneByDesc(tzdesc);
    //this.setTimezone(tz);
   

    const cpdesc  = this.lookupCallOffByID(r.calloffportalpk)?.description;
    //const cp = this.lookupCallOffByDesc(cpdesc);
    //this.setCallOffPortalKey(cp);
   
    //const langdesc  = this.lookupCallOffByID(r.languages[0])?.description;
    
    const routedesc  = this.lookupRouteByID(r.cprpk)?.descriptionWithID;
    

    console.log(tzdesc);
    console.log(cpdesc);
    console.log(routedesc);

       // Update the data on the form
      this.form.patchValue({
        id: r.accountNumber,
        TimezoneDropDown : tzdesc,
        CallOffPortalDropDown: cpdesc,
        RoutesDropDown: routedesc,
        MultiSelectLang : r.languages,
        
      });

   

    }

   
    setTimezone(tz: Timezone): void
    {
      console.log(`selected ${tz.name} ${tz.value}`);
      this.selectedTimezone = tz;
    }

    setCallOffPortalKey(cp: CalloffportalDropDown): void
    {
      console.log(`selected ${cp.description} ${cp.id}`);
      this.selectedCallOffPortal = cp;
    }


    setLanguage(tz: Language): void
    {
      console.log(`selected ${tz.id} ${tz.displayLanguage}`);
      this.selectedLanguage = tz;
    }

    setRoute(tz: IVRNextRoute): void
    {
      console.log(`selected ${tz.id} ${tz.description}`);
      this.selectedRoute = tz;
    }

    lookupTimezoneByDesc(timezonedesc: string): Timezone
    {
      const r = this.timezones.filter(a=>a.name.includes(timezonedesc))[0];
      return r;
  }
  
    lookupTimezoneByID(id: number): Timezone
    {
      const r = this.timezones.filter(a=>a.value === id)[0];
      return r;
    }


  
    lookupCallOffByDesc(cpdesc: string): CalloffportalDropDown
    {
      const r = this.calloffportalkeys.filter(a=>a.description.includes(cpdesc))[0];
      return r;
  }
  
    lookupCallOffByID(id: number): CalloffportalDropDown
    {
      const r = this.calloffportalkeys.filter(a=>a.id === id)[0];
      return r;
    }

    lookupRouteByID(id: number): IVRNextRoute
    {
      const r = this.routes.filter(a=>a.id === id)[0];
      return r;
    }
    lookupRouteByDesc(cpdesc: string): IVRNextRoute
    {
      const r = this.routes.filter(a=>a.descriptionWithID.includes(cpdesc))[0];
      return r;
  }


  private _filterInstruction(value: string): Timezone[] {
    console.log('in filter timezone' + value);
    let filterValue;
    if (value != null)
     filterValue = value.toLowerCase();
    
    return this.timezones.filter(option => option.name.toLowerCase().includes(filterValue)
    );

  }

  private _filterCallOffPortalKey(value: string): CalloffportalDropDown[] {
    console.log('in filter calloffportalkey' + value);
    let filterValue;
    if (value != null)
     filterValue = value.toLowerCase();
    
    return this.calloffportalkeys.filter(option => option.description.toLowerCase().includes(filterValue)
    );

  }

  private _filterLanguage(value: string): Language[] {
    console.log('in filter language' + value);
    let filterValue;
    if (value != null)
     filterValue = value.toLowerCase();
    
    return this.languages.filter(option => option.displayLanguage.toLowerCase().includes(filterValue)
    );

  }

  private _filterRoute(value: string): IVRNextRoute[] {
    console.log('in filter route' + value);
    let filterValue;
    if (value != null)
     filterValue = value.toLowerCase();
    
    return this.routes.filter(option => option.descriptionWithID.toLowerCase().includes(filterValue)
    );

  }

  

submit() {


  console.log("form submitted");
  console.log(this.form);

  if (this.form.valid)
  {

    if (this.form.dirty) {
     console.log('changes detected in form');
    this.accountsettings = {} as IVRAccount;
    this.accountsettings.accountNumber = this.form.controls.id.value;
    this.accountsettings.timezoneoffset =  this.lookupTimezoneByDesc(this.form.controls.TimezoneDropDown.value).value;
    this.accountsettings.calloffportalpk = this.lookupCallOffByDesc(this.form.controls.CallOffPortalDropDown.value).id;
      this.accountsettings.cprpk =  this.lookupRouteByDesc(this.form.controls.RoutesDropDown.value).id;
    
    console.log(this.form.controls.MultiSelectLang.value);

    this.accountsettings.languages = this.form.controls.MultiSelectLang.value
    console.log(this.accountsettings);
  
    this.accountservice.updateaccount(this.accountsettings)
    .subscribe({
      next: () => this.onSaveComplete(),
      error: err => this.errorMessage = err
    });

  
  }
  else
  {
    console.log(' no changes in form');
    this.onSaveComplete();
  }


   
  }
else
{
  this.errorMessage = 'Please correct the validation errors.';

}

  
  


}

onSaveComplete(): void {
  console.log('inside save complete');
  // Reset the form to clear the flags
  this.form.reset();
  this.router.navigate(['/accountselectmenu']);
}

goback(): void
{
  console.log('clicked go back');
  // this.state.changeIVRAccount(null);

  this.router.navigate(['/accountselection']);
 
}

}
