import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { StateService } from '../services/state.service';
import { ICallPathRoute } from './callpathroute';
import { CallPathRouteService } from './callpathroute.service';

@Component({
  selector: 'app-callpathroute-delete-dialog',
  templateUrl: './callpathroute-delete-dialog.component.html',
  styleUrls: ['./callpathroute-delete-dialog.component.scss']
})
export class CallpathrouteDeleteDialogComponent implements OnInit {

  routeToBeDeleted: ICallPathRoute;
  errorMessage: any;
  userid: string;

  constructor(public dialogRef: MatDialogRef<CallpathrouteDeleteDialogComponent>
    ,private state: StateService, @Inject(MAT_DIALOG_DATA) data: any, 
    private callpathrouteservice: CallPathRouteService , 
    private router: Router) {

      this.routeToBeDeleted = data.route;
      console.log(this.routeToBeDeleted);
      this.state.user.subscribe(result => {
        this.userid = result.userid
        console.log(this.userid
          )});


     }

  ngOnInit(): void {
  
  }


  onCloseConfirm(): void {

    this.callpathrouteservice.deleteRoute(+this.routeToBeDeleted.id, this.userid)
    .subscribe({
      next: () => this.onSaveComplete(),
      error: err => this.errorMessage = err
    });


    this.dialogRef.close('Confirm');
  }
  onCloseCancel(): void {
    this.dialogRef.close('Cancel');
  }

  onSaveComplete(): void {
    console.log('inside save complete');
    // Reset the form to clear the flags
    this.router.navigate(['/callpathroutes']);
  }

  

}
