import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IVR } from '../models/IVR';
import { ChartMode, ChartModeEnum } from '../models/IVRAccount';
import { IVRNode } from '../models/Node';
import { NodeService } from './node.service';
import { StateService } from './state.service';

@Injectable({
  providedIn: 'root'
})
export class NodeResolverService implements Resolve<IVR[]> {

  accountnumber: string;
  constructor(private nodeService: NodeService, private state: StateService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IVR[]> {
    console.log('inside resolve');
    let mode: ChartModeEnum;
    let userid: string;
    this.state.chartmode.subscribe(result => {
      mode =  result.currentmode;
      });
   
      this.state.user.subscribe(result => {
        userid = result.userid
          });
  
    console.log(mode);
    console.log(userid);
     

    
     this.accountnumber = route.paramMap.get('accountnumber');
     
     if (mode == ChartModeEnum.Edit || mode == ChartModeEnum.Apply)
        return  this.nodeService.getnodes(this.accountnumber,userid);
     else
       return  this.nodeService.getnodes(this.accountnumber,"");

  }
  
}
