import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IVRAccount } from '../models/IVRAccount';
import { ITemplateInfo } from '../models/TemplateInfo';
import { StateService } from '../services/state.service';
import { CallPathRouteService } from './callpathroute.service';

@Component({
  selector: 'app-callpathroute-template-dialog',
  templateUrl: './callpathroute-template-dialog.component.html',
  styleUrls: ['./callpathroute-template-dialog.component.scss']
})
export class CallpathrouteTemplateDialogComponent implements OnInit {

  templates: string[];
  errorMessage: any;
  userid: string;
  account: IVRAccount = new IVRAccount();
  templateInfo: ITemplateInfo;
  myform: FormGroup;
  selectedTemplate: string;


  constructor(private fb: FormBuilder,public dialogRef: MatDialogRef<CallpathrouteTemplateDialogComponent>
    ,private state: StateService, @Inject(MAT_DIALOG_DATA) data: any, 
    private callpathrouteservice: CallPathRouteService , 
    private router: Router) {

      this.state.user.subscribe(result => {
        this.userid = result.userid
        console.log(this.userid
          )});

          this.state.ivraccount.subscribe(result => {
            this.account = result; // this set's the username to the default observable value
            console.log(this.account.accountNumber);
            console.log(this.account.friendlyName);
      
          });
      

     }


  ngOnInit(): void {

    console.log('ngoninit of callpathtemplatedialog called');
    this.callpathrouteservice.getTemplate().subscribe({
      next: data => {
        console.log(data);
        this.templates = data;
      },
      error: err => this.errorMessage = err
    });

    console.log('inside create form');
    this.createForm();


  }

  changetemplate(value: any)
  {
    this.selectedTemplate = value;
    console.log(this.selectedTemplate);
  }
  createForm() {
    
    this.myform = this.fb.group({
      TemplateTypeDropDown: ['', Validators.required],
    });

  }


  submit() {


    console.log("form submitted");
    console.log(this.myform);
  
    if (this.myform.valid)
    {
  
      if (this.myform.dirty) {
       console.log('changes detected in form');
        this.templateInfo = {} as ITemplateInfo;
        this.templateInfo.name = this.myform.controls.TemplateTypeDropDown.value;
        this.templateInfo.account = this.account.accountNumber;
        this.templateInfo.userGUID= this.userid;
      

        this.callpathrouteservice.runTemplate(this.templateInfo)
        .subscribe({
        next: () => this.onSaveComplete(),
        error: err => this.errorMessage = err
        });


      }
    }
  }



  onCloseConfirm(): void {

    /* this.callpathrouteservice.deleteRoute(+this.routeToBeDeleted.id, this.userid)
    .subscribe({
      next: () => this.onSaveComplete(),
      error: err => this.errorMessage = err
    });
 */

    this.submit();
  }
  onCloseCancel(): void {
    this.dialogRef.close('Cancel');
  }

  onSaveComplete(): void {
    console.log('inside save complete');
    this.dialogRef.close('Confirm');

    // Reset the form to clear the flags
    this.router.navigate(['/callpathroutes']);

  }


}
