import { Component, OnInit } from '@angular/core';
import { IVRAccount } from 'src/app/models/IVRAccount';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import {  FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { StateService } from 'src/app/services/state.service';
import { NGXLogger } from 'ngx-logger';


@Component({
  selector: 'app-accountselect',
  templateUrl: './accountselect.component.html',
  styleUrls: ['./accountselect.component.scss']
})
export class AccountSelectComponent implements OnInit {

  userid: string;
  myControl = new FormControl();
  availableAccounts: IVRAccount[] ;
  filteredAccounts: Observable<IVRAccount[]>;

  selectedAccount: IVRAccount = new IVRAccount();

  constructor(private  accountservice: AccountService, private router: Router, private state: StateService, private logger: NGXLogger) { }

  ngOnInit(): void {
    console.log('inside accountselect component');
    this.loadAccounts();
    this.state.user.subscribe(result => {
      this.userid = result.userid
      this.logger.log(this.userid
        )});

        //this.state.changeIVRAccount(null);


  }


  getAccount(account: IVRAccount)
  {

    this.state.changeIVRAccount(account);

    this.logger.log('navigating to ' + account);
    if (account.calloffportalpk )
      console.log('calloff not null');
    if (account.timezoneoffset )
      console.log('timezone not null');
    if (account.languages.length > 0)
      console.log('lang len > 0')
    
    if (account.calloffportalpk && account.timezoneoffset != null && account.languages.length > 0)
      this.router.navigate(['/accountselectmenu']);
    else
      this.router.navigate(['/accountsettings']);

  }
  private loadAccounts() {
    this.accountservice.getaccounts()
     
      .subscribe(
      (accounts: IVRAccount[]) => this.handleFetchAccountsResponse(accounts),
      err => this.handleFetchAccountsError(err)
    );
  }

  private handleFetchAccountsResponse(accounts: IVRAccount[]) {
    this.availableAccounts = accounts;
    this.logger.log(this.availableAccounts);
    this.filteredAccounts = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this.filterAccount(value))
    );
  }

  private filterAccount(name: string): IVRAccount[] {
    let filterValue = name.toLowerCase();
    this.logger.log(this.availableAccounts);
    this.logger.log('filter->' + filterValue);
    return this.availableAccounts.filter(account => 
        account.friendlyName.toLowerCase().indexOf(filterValue) != -1
        || account.accountNumber.toLowerCase().indexOf(filterValue) != -1
        
      );
  }

  private handleFetchAccountsError(err: Error) {
    this.logger.error(err);
  }


}
