import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IVRAccount } from 'src/app/models/IVRAccount';
import { PendingCount } from 'src/app/models/PendingCount';
import { StagingResult } from 'src/app/models/StagingResult';
import { AccountService } from 'src/app/services/account.service';
import { DialogService } from 'src/app/services/dialog.service';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-accountpendingchanges',
  templateUrl: './accountpendingchanges.component.html',
  styleUrls: ['./accountpendingchanges.component.scss']
})
export class AccountpendingchangesComponent implements OnInit {

  errorMessage: string;
  userid : string;
  account: IVRAccount =  new IVRAccount();
  pendingcounts: PendingCount = new PendingCount();
  

  constructor( private dialog: DialogService, private  accountservice: AccountService, private route: ActivatedRoute,  private router: Router, private state: StateService) {
    //this.account.accountNumber = this.route.snapshot.paramMap.get('accountnumber');
    //this.account.friendlyName = this.route.snapshot.paramMap.get('friendlyname');

    this.state.ivraccount.subscribe(result => {
      this.account = result; // this set's the username to the default observable value
      console.log(this.account.accountNumber);
      console.log(this.account.friendlyName);

    });

    this.state.user.subscribe(result => {
                        this.userid = result.userid
                        console.log(this.userid
                          )});

                      

  }

  confirmCancelDialog(release: boolean) {
    console.log('release ->' + release);
    this.dialog
      .confirmDialog({
        title: 'Confirm Action',
        message: 'Are you sure want to discard the changes?',
        confirmCaption: 'Confirm',
        cancelCaption: 'Cancel',
      })
      .subscribe((confirmed) => {
        if (confirmed)
        {
          console.log('The user confirmed the action');
          this.discard(release);
        } 
            });
  }
  ngOnInit(): void {

    console.log('checking pending counts');
    this.accountservice.getPendingCounts(this.account.accountNumber, this.userid).subscribe({
                           next: counts => {
                             console.log(counts);
                             this.pendingcounts = counts;
                           },
                           error: err => this.errorMessage = err
     });
                      
   
  }

  continue(): void
  {
    this.router.navigate(['/callpathroutes']);
  }

  
  discard(release : boolean): void
  {


    
    this.accountservice.deleteAccounInStaging( this.account.accountNumber,this.userid)
    .subscribe(
      (resp: StagingResult) => this.handleDeleteStagingResponse(resp, release),
      err => {this.errorMessage = err;console.log(err)}
    );
    
    
  }


  private handleDeleteStagingResponse(resp: StagingResult, release: boolean) {
    console.log('handle delete staging');
    console.log(resp);
    
      if (resp.errorMessage.length > 0)
      {
        console.error(resp.errorMessage);
        this.errorMessage = resp.errorMessage;
        return;
      }
      if (resp.recordFound == 0)
      {
       console.error('hmmmm should not have happened, did someone else delete record already');
      }
      if (resp.recordFound == 1)
      {
        // the user chose to release the record
        if (release)
        {
            this.router.navigate(['/accountselectmenu']);
            return;
        }
        // call create staging again so we have fresh records
        this.accountservice.createIVRAccountInStaging( this.account.accountNumber,this.userid)
        .subscribe(
        (resp: StagingResult) => {
          console.log(resp);
        if (resp.errorMessage.length > 0)
          {
            console.error(resp.errorMessage);
            this.errorMessage = resp.errorMessage;
            return;
          }
    
        if (resp.recordFound == 0)
        {
          this.router.navigate(['/callpathroutes']);
      
        }
    
        if (resp.recordFound == 1)
        {
          console.error('this should not have happened, the record should have been deleted already')
          this.errorMessage = "this should not have happened, the record should have been deleted already, try again";
        }
        
    
    },
    err => {this.errorMessage = err;console.log(err);
    }
  );
    


   
    }
    

  }






  goback(): void
  {
    this.router.navigate(['/accountselectmenu']);
   
  }
}
