<div *ngIf="loading">
    Please wait while we load your data....
    <mat-spinner [diameter]="60"></mat-spinner>
  </div>
  <div *ngIf="!loading">
    <fieldset>
      <legend>{{ pageTitle }}</legend>
      <form [formGroup]="form" (ngSubmit)="submit()">
        <div class="form-container">

          <div class="flex-item">
            
            <mat-label class="warningmessage" *ngIf="incompletesettings">Our records indicate that one or more settings for this account must be configured before you can proceed.</mat-label>
          </div>
          <p></p>
          <div class="flex-item">
            <mat-form-field appearance="outline">
              <mat-label
                >Select Timezone for this account</mat-label
              >
  
              <input
                type="text"
                matInput
                formControlName="TimezoneDropDown"
                [matAutocomplete]="TimezoneDropDown"
              />
              <mat-autocomplete #TimezoneDropDown="matAutocomplete">
                <mat-option
                  *ngFor="let tz of filteredtimezones | async"
                  [value]="tz.name"
                  (onSelectionChange)="setTimezone(tz)"
                  >{{ tz.name }}
                </mat-option>
              </mat-autocomplete>
  
              <mat-error
                *ngIf="
                  this.form.controls.TimezoneDropDown.hasError('required')
                "
              >
                Timezone is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
      
        
         
          <div class="flex-item">
            <mat-form-field appearance="outline">
              <mat-label
                >Select CalloffPortal  for this account</mat-label
              >
  
              <input
                type="text"
                matInput
                formControlName="CallOffPortalDropDown"
                [matAutocomplete]="CallOffPortalDropDown"
              />
              <mat-autocomplete #CallOffPortalDropDown="matAutocomplete">
                <mat-option
                  *ngFor="let tz of filteredcalloffportalkeys | async"
                  [value]="tz.description"
                  (onSelectionChange)="setCallOffPortalKey(tz)"
                  >{{ tz.description }}
                </mat-option>
              </mat-autocomplete>
  
              <mat-error
                *ngIf="
                  this.form.controls.CallOffPortalDropDown.hasError('required')
                "
              >
                Calloffportal assignment is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
      
          <div class="flex-item">
              <ng-multiselect-dropdown formControlName="MultiSelectLang"
              [placeholder]="'Select Languages that are supported for this account'"
              [settings]="dropdownSettings"
              [data]="languages"
            (onSelect)="onItemSelect($event)"
              (onSelectAll)="onSelectAll($event)"
            >
            </ng-multiselect-dropdown>
            <mat-error
            *ngIf="
              this.form.controls.MultiSelectLang.hasError('required')
            "
          >
            Language is <strong>required</strong>
          </mat-error>
            <p></p>
          </div>

<!--
          <div class="flex-item">
            <mat-form-field appearance="outline">
              <mat-label
                >Select Languages for this account</mat-label
              >
  

              <input
                type="text"
                matInput
                formControlName="LanguagesDropDown"
                [matAutocomplete]="LanguagesDropDown"
              />
              <mat-autocomplete #LanguagesDropDown="matAutocomplete">
                <mat-option
                  *ngFor="let tz of filteredlanguages | async"
                  [value]="tz.displayLanguage"
                  (onSelectionChange)="setLanguage(tz)"
                  >{{ tz.displayLanguage }}
                </mat-option>
              </mat-autocomplete>
  
              <mat-error
                *ngIf="
                  this.form.controls.LanguagesDropDown.hasError('required')
                "
              >
                Language is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
        -->
          <div class="flex-item">
            <mat-form-field appearance="outline">
              <mat-label
                >Select First Instruction for this account</mat-label
              >
  
              <input
                type="text"
                matInput
                formControlName="RoutesDropDown"
                [matAutocomplete]="RoutesDropDown"
              />
              <mat-autocomplete #RoutesDropDown="matAutocomplete">
                <mat-option
                  *ngFor="let tz of filteredroutes | async"
                  [value]="tz.descriptionWithID"
                  (onSelectionChange)="setRoute(tz)"
                  >{{ tz.descriptionWithID }}
                </mat-option>
              </mat-autocomplete>
  
            </mat-form-field>
          </div>
      
        
        
        
        
       
       
          <div class="flex-item3">
            <input matInput formControlName="id" hidden />
  
            <button mat-raised-button color="primary" (click)="goback()">
              Cancel
            </button>
            <button
              mat-raised-button
              color="primary"
              type="submit"
              [disabled]="!form.valid"
            >
              Update Settings
            </button>
          </div>
        </div>
      </form>
    </fieldset>
  
    <div class="warningmessage" *ngIf="errorMessage">{{ errorMessage }}</div>
  </div>
  