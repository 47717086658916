import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import { ITemplateInfo } from "../models/TemplateInfo";
import { MyMonitoringService } from "../services/my-monitoring-service.service";
import { ICallPathRoute } from "./callpathroute";

@Injectable({
  providedIn: 'root'
})
export class CallPathRouteService {
  
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      Authorization: 'my-auth-token',
      'Apikey': environment.Apikey
    })
  };
  

  constructor(private http: HttpClient,
    private myMonitoringService: MyMonitoringService) { }

  getRoutes(accountnumber: string, username: string): Observable<ICallPathRoute[]> {
    
    const url:string  = `${environment.apiUrl}CallPathRoutes?AccountNumber=${accountnumber}&username=${username}`;
    console.log(url);
   
    return this.http.get<any[]>(url, this.httpOptions);
  }

  getRoute(accountnumber: string, username: string, id: string): Observable<ICallPathRoute> {
    
    const url:string  = `${environment.apiUrl}CallPathRoutes?AccountNumber=${accountnumber}&username=${username}&id=${id}`;
    console.log(url);
   
    return this.http.get<any>(url,  this.httpOptions);
  }


  getTemplate(): Observable<string[]> {
    
    const url:string  = `${environment.apiUrl}Template`;
    console.log(url);
   
    return this.http.get<any[]>(url,  this.httpOptions);
  }

  runTemplate(templateInfo: ITemplateInfo): Observable<string> {
    
    //const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url:string  = `${environment.apiUrl}Template`;
    
    return this.http.post<string>(url, JSON.stringify(templateInfo),  this.httpOptions )
      .pipe(
        tap(data => console.log('runTemplate: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  

  }


  createRoute(route: ICallPathRoute): Observable<ICallPathRoute> {
   // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url:string  = `${environment.apiUrl}CallPathRoutes`;
    
    route.id = null;
    console.log('calling create route');
    console.log(JSON.stringify(route));
    return this.http.post<ICallPathRoute>(url, JSON.stringify(route), this.httpOptions)
      .pipe(
        tap(data => console.log('createRoute: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  deleteRoute(id: number, username: string): Observable<{}> {
    //const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url:string  = `${environment.apiUrl}CallPathRoutes?id=${id}&username=${username}`;

    return this.http.delete<ICallPathRoute>(url, this.httpOptions)
      .pipe(
        tap(data => console.log('deleteRoute: ' + id)),
        catchError(this.handleError)
      );
  }

  updateRoute(route: ICallPathRoute): Observable<ICallPathRoute> {
    //const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url:string  = `${environment.apiUrl}CallPathRoutes`;

    return this.http.put<ICallPathRoute>(url, JSON.stringify(route), this.httpOptions)
    .pipe(
      tap(data => console.log('updateRoute: ' + JSON.stringify(data))),
      catchError(this.handleError)
    );


    }


  // getRoute(id: number): Observable<ICallPathRoute | undefined> {
  //   return this.getRoutes()
  //     .pipe(
  //       map((callpathroutes: ICallPathRoute[]) => callpathroutes.find(p => p.id === id))
  //     );
  // }

  private handleError(err: HttpErrorResponse): Observable<never> {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    this.myMonitoringService.logException(err);
       
    console.error(errorMessage);
    return throwError(errorMessage);
  }

}
