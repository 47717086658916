import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {Observable, of} from "rxjs";
import * as _ from 'lodash';
import { catchError, map, tap } from 'rxjs/operators';
import {IVRNode} from '../models/Node';
import { IVRLink } from '../models/NodeLink';
import { environment } from '../../environments/environment';
import { IVR } from "../models/IVR";
import { MyMonitoringService } from "./my-monitoring-service.service";

@Injectable({
  providedIn: 'root'
})
export class NodeService {

  //https://localhost:44383/api/ivr/123/nodes
  //https://localhost:44383/api/ivr/123/links
    
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      Authorization: 'my-auth-token',
      'Apikey': environment.Apikey
    })
  };
  
  
  constructor(private http: HttpClient,
    private myMonitoringService: MyMonitoringService) { 

    console.log(environment.apiUrl);

  }

  getnodes(accountnumber: string, userid: string): Observable<IVR[]>
  {
      let url: string;
      if (userid.length > 0)
       url  = environment.apiUrl + accountnumber + "/nodes?username=" + userid;
      else
        url  = environment.apiUrl + accountnumber + "/nodes";
      
      console.log(url);
      return  this.http
                      .get<IVR[]>(url, this.httpOptions)
                      .pipe(
                          tap(_ => console.log('fetched nodes')),
                          catchError(this.handleError<IVR[]>('getnodes', []))
                        );
                      
                     

  }
  getlinks(accountnumber: string , userid: string): Observable<IVRLink[]>
  {
    let url: string;
      if (userid.length > 0)
       url  = environment.apiUrl + accountnumber + "/links?username=" + userid;
      else
        url  = environment.apiUrl + accountnumber + "/links";
      
    console.log(url);
      return this.http
                      .get<IVRLink[]>(url, this.httpOptions)
                      .pipe(
                          tap(_ => console.log('fetched links')),
                          catchError(this.handleError<IVRLink[]>('getlinks', []))
                      );
                      
                      
                      
  }

  private handleError<T>(operation = 'operation', result?: T){
  return (error: any): Observable<T> => {

    this.myMonitoringService.logException(error);
       
    // TODO: send the error to remote logging infrastructure
    console.error(error); // log to console instead

    // TODO: better job of transforming error for user consumption
    console.log(`${operation} failed: ${error.message}`);

    // Let the app keep running by returning an empty result.
    return of(result as T);
  };
  }
}
