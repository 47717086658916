<fieldset>
	<legend>  Upload Recording</legend>
  
    <div class="message">{{infoMessage}}</div> 
	<div class="form-container">
		<form  [formGroup]="form" (ngSubmit)="submit()">
	  
			 <div class="flex-item">
  
		
			  <input   type="file" #file placeholder="Choose file" (change)="onChange($event)" style="display:none;">
			  <button mat-button color="primary" type="button" class="btn btn-success" (click)="file.click()">

				<mat-icon>attach_file</mat-icon>
				Upload file

			  </button>

			  <mat-label>{{filename || "No file uploaded yet."}}
			</mat-label>

			
			</div>
			  <div class="flex-item" >
  
				<mat-form-field appearance="outline">
					<mat-label>Pick the language </mat-label>
					<mat-select formControlName ="LanguagesDropDown" >
						<mat-option *ngFor="let rec of this.languages"
						[value]="rec.language">{{rec.displayLanguage}}</mat-option>
					</mat-select>
				  </mat-form-field>
		
			  </div>
			 
			  <div class="flex-item">
  
				
					<mat-checkbox  formControlName="CommonRecordingInput">Common/Shared Recording</mat-checkbox>
			  </div>

			<div class="flex-item">
  

			  <mat-form-field  appearance="outline">

				<mat-label>Description</mat-label>
				<input matInput formControlName="DescriptionInput" placeholder="Enter description for this instruction" required>
				<mat-error *ngIf="this.form.controls.DescriptionInput.hasError('required')">
				  Description is <strong>required</strong>
				</mat-error>
			  </mat-form-field>
			</div>

			
			<div class="flex-item">
  

				<mat-form-field  appearance="outline">
  
				  <mat-label>Transcription</mat-label>
				  <textarea matInput formControlName="TranscriptionInput" placeholder="Enter description for this instruction" required></textarea>
				  <mat-error *ngIf="this.form.controls.TranscriptionInput.hasError('required')">
					Transcription is <strong>required</strong>
				  </mat-error>
				</mat-form-field>
			  </div>
  
			  
			<div class="flex-item3">
				
				<button mat-raised-button color="primary" (click)="goback()" >Cancel</button>
			  <button mat-raised-button color="primary" type="submit" [disabled] = "!form.valid || !this.filename"  >Create Recording</button>
			</div>

			
		
</form>
	</div>

</fieldset>

<fieldset>
	<legend>Recordings for this account</legend>

<ag-grid-angular
style="width: 100%; height: 300px;"
    class="ag-theme-balham"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
>
</ag-grid-angular>
</fieldset>


