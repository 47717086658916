import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { IVRAccount } from './models/IVRAccount';
import { MyMonitoringService } from './services/my-monitoring-service.service';
import { StateService } from './services/state.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'IVRFlowClient';
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();


  username : string;  
  userid: string;
  account: IVRAccount =  new IVRAccount();
  IsAdminUser: boolean;



  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
     private state:StateService,
     private myMonitoringService: MyMonitoringService
  ) { 

    this.state.ivraccount.subscribe(result => {
      this.account = result; // this set's the username to the default observable value
      console.log(this.account.accountNumber);
      console.log(this.account.friendlyName);

    });

    this.state.user.subscribe(result => {
      this.username = result.username
      this.userid = result.userid;
      this.checkIfAdmin();
      console.log(this.username
        )});

      


  }

  checkIfAdmin()
  {
     //akash
     if (this.userid == "698c1975-dc3f-44d8-95a8-6e3f6202ee9c"  
     ||
     // akash 2 for test
     this.userid == "06176e58-a369-45a5-82d7-07da379f3fe8"
     // michael
     || this.userid == "ad41872e-4e6f-46ba-a7e0-027cdcab692d" 
     //brandon
     || this.userid == "557f6079-1539-4848-9f64-e447c2411de1" 
     // nick
     || this.username == "293577a8-b17d-4afe-b34a-1ce761a2fcf9" )
       this.IsAdminUser = true;
     else
     this.IsAdminUser = false;
    console.log('admin->' + this.IsAdminUser);
  }
  
  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    console.log('ngoninit');
    
    console.log(this.isIframe);
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
  
      });


 }
  

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
            this.myMonitoringService.appInsights.setAuthenticatedUserContext(response.account.username);
          });
      } else {
        this.authService.loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
            this.myMonitoringService.appInsights.setAuthenticatedUserContext(response.account.username);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }
  }

  logout() {
    this.myMonitoringService.appInsights.clearAuthenticatedUserContext();
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/"
      });
    } else {
      this.authService.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
