import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccountSelectComponent } from './components/accountselect/accountselect.component';
import { ViewChartComponent } from './components/viewchart/viewchart.component';
import { AppMaterialModule } from './modules/app-material.module';
import { AppRoutingModule } from './modules/app-routing.module';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { GojsAngularModule } from 'gojs-angular';

import { InspectorComponent } from './components/inspector/inspector.component';
import { NodeService } from './services/node.service';
import { AccountService } from './services/account.service';
import { AudioComponent } from './components/audio/audio.component';
import { NgxAudioPlayerModule } from 'ngx-audio-player';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { HomeComponent } from './components/home/home.component';
import { CallPathRouteModule } from './callpathroute/callpathroute.module';
import { AccountselectmenuComponent } from './components/accountselectmenu/accountselectmenu.component';
import { AccountpendingchangesComponent } from './components/accountpendingchanges/accountpendingchanges.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CallpathrouteDeleteDialogComponent } from './callpathroute/callpathroute-delete-dialog.component';
import { CallpathrouteTemplateDialogComponent } from './callpathroute/callpathroute-template-dialog.component';
import { SelectrecordingComponent } from './components/selectrecording/selectrecording.component';
import { UploadrecordingComponent } from './components/uploadrecording/uploadrecording.component';
import { AddCommonRecordingComponent } from './components/addcommonrecording/addcommonrecording.component';
import { UpdaterecordingComponent } from './components/updaterecording/updaterecording.component';
import { DeleterecordingComponent } from './components/deleterecording/deleterecording.component';
import { ViewpendingchangesComponent } from './components/viewpendingchanges/viewpendingchanges.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { ProcesschangesComponent } from './components/processchanges/processchanges.component';
import { ErrorHandlerService } from './errorhandler';
import { LoggerModule } from 'ngx-logger';
import { AccountsettingsComponent } from './components/accountsettings/accountsettings.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ConfirmdialogComponent } from './components/confirmdialog/confirmdialog.component';
import { AgGridModule } from 'ag-grid-angular';
import { OrderphoneComponent } from './components/orderphone/orderphone.component';
import { PhoneFormatPipe } from './shared/phone-format.pipe';
import { OrderphoneconfirmationComponent } from './components/orderphoneconfirmation/orderphoneconfirmation.component';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {

      clientId: environment.clientId,
      authority: environment.authority,

      redirectUri: environment.redirectUri
   
      /*
      clientId: '1f237716-22ba-4b27-96d9-b07188a213ea',
      authority: 'https://login.microsoftonline.com/d6fa6de8-2d50-46cf-86c5-206f9710368c',
      redirectUri: 'http://localhost:4200/'
*/
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    }
  };
}


@NgModule({
  declarations: [
    AppComponent,
    AccountSelectComponent,
    AccountsettingsComponent,
    ViewChartComponent,
    InspectorComponent,
    AudioComponent,
    HomeComponent,
    AccountselectmenuComponent,
    AccountpendingchangesComponent,
    CallpathrouteDeleteDialogComponent,
    CallpathrouteTemplateDialogComponent,
    SelectrecordingComponent,
    UploadrecordingComponent,
    AddCommonRecordingComponent,
    UpdaterecordingComponent,
    DeleterecordingComponent,
    ViewpendingchangesComponent,
    ConfirmationComponent,
    ProcesschangesComponent,
    AccountsettingsComponent,
    ConfirmdialogComponent,
    OrderphoneComponent,
    PhoneFormatPipe,
    OrderphoneconfirmationComponent
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppMaterialModule,

    FormsModule ,
    ReactiveFormsModule,
    GojsAngularModule,
    HttpClientModule,
    NgxAudioPlayerModule,
    MsalModule,
    CallPathRouteModule,
    AppRoutingModule,
    FontAwesomeModule,
    NgMultiSelectDropDownModule,
    AgGridModule.withComponents([]),
   
    LoggerModule.forRoot({
      serverLoggingUrl: `${environment.logapiUrl}`,
      level:environment.logLevel,
      serverLogLevel: environment.serverLogLevel,
      disableConsoleLogging: false
    })

    
  
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {
    provide: ErrorHandler, 
    useClass: ErrorHandlerService
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    NodeService,
    AccountService
    
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]

})
export class AppModule { }
