
<div class="center">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Our records indicate that this account {{ this.account.accountNumber }} - {{ this.account.friendlyName }} has following pending changes. 
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        
        <mat-list>
            <mat-list-item> 
                New instructions added: {{ pendingcounts.inserts }}
                 </mat-list-item>
            <mat-list-item> 
                Existing Instructions updated: {{ pendingcounts.updates }}    
            </mat-list-item>
            <mat-list-item> 
              Existing Instructions Deleted: {{ pendingcounts.deletes }}    
            </mat-list-item>

        </mat-list>
        <p class="warningmessage" *ngIf="this.viewedchart !='Y' ">        You must view chart first before you can apply the changes. Click continue to view chart.
        </p>
           
        <p  class="infomessage" *ngIf="this.viewedchart =='Y'  ">  Since you have viewed the chart now, if all looks good Click continue to apply changes. Once successful, you will get a confirmation number for this change.
        </p>
      
        
      </mat-card-content>
    
      <mat-card-actions>
        <button mat-stroked-button color="primary" (click)="goback();">Go Back</button>
        <button mat-stroked-button color="primary" (click)="Continue();">Continue</button>
     
      </mat-card-actions>
    
    </mat-card>
    </div>
    
    <div *ngIf="errorMessage"
         class="warningmessage">
      Error: {{ errorMessage }}
    </div>